import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }



  getShareByUserSlug(slug: string ) : Promise<any> {
    return this.httpService.get(`shares/${slug}/detail`);
  }



}
