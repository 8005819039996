
<div class="container">
    <div class="fade show" style="display: block;" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered ">
            <div class="modal-content modal-1">
                <div class="modal-body modal-2">
                    <div class="text1" *ngIf="status=='approved'">
                          Pago realizado correctamente.
                        <div class="texto2">
                            Gracias por tu compra.
                            <div class="cerrar-btn" (click)="irAHome()">
                                Cerrar
                            </div>
                        </div>
                    </div>
                    <div class="text1" *ngIf="status=='reject'">
                        Pago rechazado.
                        <div class="texto2">
                            Hubo problemas para completar la operación
                            <div class="cerrar-btn" (click)="irAlCarrito()">
                                Volver al carrito
                            </div>
                        </div>
                    </div>
                    <div class="text1" *ngIf="status=='expired'">
                        Tiempo de pago excedido
                        <div class="texto2">
                            El tiempo para realizar el pago ha finalizado, realice nuevamente su compra.

                            <div class="cerrar-btn" (click)="irAHome()">
                                Inicio
                            </div>
                        </div>
                    </div>
                    <div class="text1" *ngIf="status=='fail'">
                        Hubo un error
                        <div class="texto2">
                           Algo no salio bien, por favor intente nuevamente
                            <div class="cerrar-btn" (click)="irAHome()">
                                Inicio
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

