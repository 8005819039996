import { Image } from './image';
import { ProductVariation } from './productVariation';

export class Product{

    categories: any[];
    created_at: string;
    desc: any;
    height: number;
    html_description: any;
    html_short_description: any;
    images: Image[];
    variations: any[];
    length?: number;
    manufacturer: string;
    material: string;
    name: string;
    price: number;
    sale_price: number;
    sku: string;
    status: any;
    tags?:any;
    theme: string;
    title: string;
    type: string;
    updated_at:string;
    weight: number;
    width: number;

    constructor(objeto:any){

        this.height = objeto.height;
        this.length = objeto;
        this.manufacturer = objeto.manufacturer;
        this.material = objeto.material;
        this.name = objeto.name;
        this.price = objeto.price;
        this.sale_price = objeto.sale_price;
        this.sku = objeto.sku;
        this.title = objeto.title;
        this.type = objeto.type;
        this.weight = objeto.weight;
        this.width = objeto.width;

        this.images = [];

        objeto.images.forEach((img) => {
          this.images.push(img);
        });

 
        this.categories = [];    
        this.categories = objeto.categories;
        this.variations = objeto.variations;
        this.html_description = objeto.html_description;
    }

    // categories: "category_1,category_3"
    // created_at: "2020-08-15T00:35:33.000000Z"
    // desc: null
    // height: 882.1921
    // html_description: "<html><head><title>Tempore aperiam quo ex pariatur doloribus accusamus eveniet.</title></head><body><form action="example.net" method="POST"><label for="username">provident</label><input type="text" id="username"><label for="password">tempore</label><input type="password" id="password"></form><i>Consequatur autem quis itaque.</i>Eum ipsam nihil nulla maxime praesentium molestias.<h2>Molestiae ullam.</h2></body></html>↵"
    // html_short_description: "<html><head><title>Voluptatibus sapiente consectetur qui ipsa ea dolorem eos nesciunt officiis.</title></head><body><form action="example.org" method="POST"><label for="username">enim</label><input type="text" id="username"><label for="password">architecto</label><input type="password" id="password"></form><h2>Distinctio blanditiis error eos animi recusandae.</h2><table><thead><tr><th>Nam est.</th><th>Nemo et ipsum aut accusantium.</th><th>Consequatur.</th></tr></thead><tbody><tr><td>Dolor tempore adipisci aspernatur delectus eum magnam.</td><td>Et consequatur.</td><td>Eum veniam.</td></tr><tr><td>Ea.</td><td>Et eligendi aspernatur quia repellat porro sit ut aut quaerat.</td><td>Culpa officia tempore sint nostrum velit ut vitae aut.</td></tr><tr><td>Dolores ea eos impedit enim ex.</td><td>Et vel repellendus aut fugit est.</td><td>Sit excepturi est.</td></tr><tr><td>Facere animi voluptatem excepturi maiores.</td><td>Nam id cupiditate sit.</td><td>Minus voluptate harum aliquam asperiores officiis velit quod nulla tempore ullam enim quibusdam.</td></tr><tr><td>Necessitatibus vero rerum aut aut quia repellendus minima id debitis debitis et reiciendis nisi.</td><td>Consequatur harum et explicabo.</td><td>Omnis.</td></tr><tr><td>Et ipsa tempore delectus nihil.</td><td>Non optio.</td><td>Qui explicabo quam.</td></tr><tr><td>Accusamus sed.</td><td>Quos velit modi aut dolores et dignissimos minus incidunt enim facilis omnis.</td><td>Aut consequatur sunt voluptatem.</td></tr></tbody></table></body></html>↵"
    // images: (13) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
    // length: 778
    // manufacturer: "Spraying Machine Operator"
    // material: "material_d"
    // name: "Luella Bergnaum"
    // price: 8352743.3
    // sale_price: 1844985.01
    // sku: "sB2020K0815s123533HE"
    // status: "active"
    // tags: null
    // theme: "theme_b"
    // title: "Aircraft Launch and Recovery Officer"
    // type: "type_b"
    // updated_at: "2020-08-15T00:35:33.000000Z"
    // weight: 127.7
    // width: 796.89

}
