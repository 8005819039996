import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/classes/product';
import { ProductsService } from 'src/app/services/products/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../../layout/header/header.component';
import { isNullOrUndefined } from 'util';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartService } from './../../services/cart/cart.service';
import { MetaService } from 'ng2-meta';
import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { StoresService } from 'src/app/services/stores/stores.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
  animations: [
    trigger('changeDivDimension', [
      transition('initial=>final', animate('1000ms', keyframes([
        style({ opacity: '0', bottom: "0px", offset: 0 }),
        style({ opacity: '1', bottom: "-22px", offset: 0.30 }),
        style({ opacity: '1', bottom: "-22px", offset: 0.80 }),
        style({ opacity: '0', bottom: "-10px", offset: 1 })
      ]))),
      transition('final=>initial', animate('1000ms', keyframes([
        style({ opacity: '0', bottom: "0px", offset: 0 }),
        style({ opacity: '1', bottom: "-22px", offset: 0.30 }),
        style({ opacity: '1', bottom: "-22px", offset: 0.80 }),
        style({ opacity: '0', bottom: "-10px", offset: 1 })
      ])))
    ]),
  ]
})
export class ProductPageComponent implements OnInit {
  produImg: any = '';
  product: any;
  public promotionProduct: any = false;
  public promoActive: any = false;
  public productsPromo: any = [];
  public salePrice: any = 0;
  productoCargado: boolean = false;
  idParametro: any;
  indiceFoto: number = 0;
  itemCantidad: number = 1;
  header: HeaderComponent;
  tienefoto: boolean = false;
  currentState = 'initial';
  current_product: any = {};
  cart: any = [];
  desc: string = '';
  public id_store_current = "";
  constructor(
    private porductService: ProductsService,
    private route: ActivatedRoute,
    private PromotionsServices: PromotionsServices,
    private metaService: MetaService,
    private StoresService: StoresService,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private router: Router
  ) {

    this.spinner.show();
  }

  ngOnInit(): void {
    // 
    /** spinner ends after 5 seconds */
    this.route.params.subscribe(params => {
      this.idParametro = params['id'];
      if (params['code'] !== undefined) {
        localStorage.setItem('user_reference', params['code']);
      }
      this.StoresService.getStoreId()
        .subscribe((id: any) => {
          if (id != "null" && id != null) {
            this.id_store_current = id;
            this.PromotionsServices.getPromotion()
              .subscribe((promotion: any) => {
                this.promotionProduct = promotion;
                if (promotion && this.promotionProduct != false && (this.promotionProduct.type == '004' || this.promotionProduct.type == '002')) {
                  this.PromotionsServices.isProductInPromotion(this.id_store_current, this.idParametro).then((res2) => {

                    if (res2.state == "success") {


                      if (res2.data.length != 0) {
                        this.promoActive = true;

                        this.productsPromo = res2.data;
                      } else {

                        this.promoActive = false;
                      }
                    } else {
                      this.promoActive = false;
                    }
                  }).catch((error) => {
                    this.promoActive = false;
                  });
                }
                this.traerProducto(this.idParametro);
              });

          }


        });



    });
    this.cartService.getCart()
      .subscribe((cart: any) => {
        this.cart = cart;
      });



  }
  ngAfterViewChecked() {

  }

  async setVariation(variation: any, index: number) {

    this.current_product = Object.assign({}, variation);
    this.current_product.description = (variation.description != undefined && variation.description != null) ? variation.description : this.product.name;
    this.current_product.price = variation.price;
    this.current_product.image = (this.product.images[index] != undefined) ? this.product.images[index].url : 'https://i.imgur.com/ipMEqUS.jpg';
    this.current_product.cod = `${variation.cod}`;
    this.current_product.sku = `${variation.sku}`;

    if (this.promotionProduct && this.promotionProduct != false && this.promotionProduct.type == '004') {

      if (await this.revisarPromo4Core()) {
        this.salePrice = 0;

        for (var i = 0; i < this.productsPromo.length; i++) {
          if (this.productsPromo[i].cod == this.current_product.cod) {
            this.salePrice = this.productsPromo[i].sale_price;

          }
        }
      }

    }else if(this.promotionProduct && this.promotionProduct != false && this.promotionProduct.type == '002'){
      if (await this.revisarPromo2Core()) {
        this.salePrice = 0;

        for (var i = 0; i < this.productsPromo.length; i++) {
          if (this.productsPromo[i].cod == this.current_product.cod) {
            this.salePrice = this.productsPromo[i].sale_price;

          }
        }
      }
    }

  }

  traerProducto(pId: any) {
    this.porductService.getProductBySku(pId, this.id_store_current).then(respuesta => {
      console.log(respuesta);
      this.product = respuesta.data;
      console.log(this.product.variations[0]);
      this.product.variations = this.product.variations.filter(va => va.price > 0);
      let idx=0;
      for (var i = 0; i < this.product.variations.length; i++) {
        if(this.product.variations[i].stock>0){
          idx=i;
          break;
        }
      }

        this.setVariation(this.product.variations[idx],idx);
   
      this.productoCargado = true;



      if (!isNullOrUndefined(this.product) && this.product.images.length > 0 && !isNullOrUndefined(this.product.images[0])) {
        this.tienefoto = true;
      }
      //this.metaDataProduct();
      this.spinner.hide();
    })

  }

  metaDataProduct() {

    this.metaService.setTitle(this.product.name + " " + this.current_product.finalSku + " | PANDORA by FlexyStore");
    this.metaService.setTag('og:title', this.product.name + " " + this.current_product.finalSku + " | PANDORA by FlexyStore");
    this.metaService.setTag('og:description', this.current_product.description);
    this.metaService.setTag('og:image', this.current_product.image);

  }
  addCantidad() {
    if (this.current_product.stock == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No hay stock disponible del producto'
      });

      return;
    }
    for (var i = 0; i < this.product.variations.length; i++) {
      if (this.product.variations[i].cod == this.current_product.cod) {
        if (this.product.variations[i].stock >= this.itemCantidad + 1) {
          this.itemCantidad++;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No hay stock disponible del producto'
          });
        }
      }
    }
  }
  removeCantidad() {
    if (this.itemCantidad > 1)
      this.itemCantidad--;

  }
  async addItemTocart() {

    let exist = false;
    if (this.current_product.stock == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No hay stock disponible del producto'
      });
      exist = true;
      return;
    }
    for (var i = 0; i < this.cart.length; i++) {
    
      if (this.cart[i].variation.product_sku == this.current_product.product_sku) {
        console.log(this.cart[i].variation.quantity ,this.itemCantidad, this.current_product.stock)
        if ((this.cart[i].variation.quantity + this.itemCantidad) <= this.current_product.stock) {
          exist = true;
          this.cartService.addItemTocart(this.product, this.current_product, this.itemCantidad);
          this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
          if (this.promotionProduct != false && this.promotionProduct.type == '004') {
            this.spinner.show();
            if (await this.revisarPromo4Core()) {
              this.salePrice = 0;
              for (var i = 0; i < this.productsPromo.length; i++) {
                if (this.productsPromo[i].cod == this.current_product.cod) {
                  this.salePrice = this.productsPromo[i].sale_price;
                }
              }
            }
            this.spinner.hide();

          } else if (this.promotionProduct != false && this.promotionProduct.type == '002') {
            if (await this.revisarPromo2Core()) {
              this.salePrice = 0;
              for (var i = 0; i < this.productsPromo.length; i++) {
                if (this.productsPromo[i].cod == this.current_product.cod) {
                  this.salePrice = this.productsPromo[i].sale_price;
                }
              }
            }
            this.spinner.hide();
          }

        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'La cantidad seleccionada excede el stock existente'
          });
        }
        return;
      }

    }
    if (!exist) {
      this.cartService.addItemTocart(this.product, this.current_product, this.itemCantidad);
      this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
    }
    if (this.promotionProduct != false && this.promotionProduct.type == '004') {
      this.spinner.show();
      if (await this.revisarPromo4Core()) {
        this.salePrice = 0;
        for (var i = 0; i < this.productsPromo.length; i++) {
          if (this.productsPromo[i].cod == this.current_product.cod) {
            this.salePrice = this.productsPromo[i].sale_price;
          }
        }
      }
      this.spinner.hide();
    } else if (this.promotionProduct != false && this.promotionProduct.type == '002') {
      this.spinner.show();
      if (await this.revisarPromo2Core()) {
        this.salePrice = 0;
        for (var i = 0; i < this.productsPromo.length; i++) {
          if (this.productsPromo[i].cod == this.current_product.cod) {
            this.salePrice = this.productsPromo[i].sale_price;
          }
        }
      }
      this.spinner.hide();

    }
  }

  mostrarFoto(pIndice: number) {
    this.indiceFoto = pIndice;
  }

  buy() {



    let exist = false;
    if (this.current_product.stock == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No hay stock disponible del producto'
      });
      exist = true;
      return;
    }

    for (var i = 0; i < this.cart.length; i++) {
      if (this.cart[i].variation.product_sku == this.current_product.product_sku) {


        if ((this.cart[i].variation.quantity + this.itemCantidad) <= this.current_product.stock) {
          exist = true;
          this.cartService.addItemTocart(this.product, this.current_product, this.itemCantidad);
          this.router.navigateByUrl(`/${this.id_store_current}/cart`);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'La cantidad seleccionada excede el stock existente'
          });
        }
        return;
      }

    }

    if (!exist) {
      this.cartService.addItemTocart(this.product, this.current_product, this.itemCantidad);
      this.router.navigateByUrl(`/${this.id_store_current}/cart`);
    }
  }

  async revisarPromo4Core() {
    let productPromo = 0;
    let cntProducts = 5;


    for (var i = 0; i < this.cart.length; i++) {
      let producto_en_promo = 0;
      let __cart = this.cart[i];
      let cantidad = await this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

        if (res2.state == "success") {

          if (res2.data.length != 0) {
            let productos_promos = res2.data;
            for (var j = 0; j < productos_promos.length; j++) {
              if (__cart.variation.cod == productos_promos[j].cod) {

                return __cart.variation.quantity;
              }
            }
          }
        }
      }).catch((error) => {
        return false;
      });
      if (cantidad != undefined)
        productPromo += Number(cantidad);

    }

    if (productPromo >= cntProducts) {
      return true;
    } else {
      return false;
    }


  }

  async revisarPromo2Core() {
    let productPromo = 0;


    for (var i = 0; i < this.cart.length; i++) {
      let producto_en_promo = 0;
      let __cart = this.cart[i];
      let cantidad = await this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

        if (res2.state == "success") {

          if (res2.data.length != 0) {
            let productos_promos = res2.data;
            for (var j = 0; j < productos_promos.length; j++) {
              if (__cart.variation.cod == productos_promos[j].cod) {

                return __cart.variation.quantity;
              }
            }
          }
        }
      }).catch((error) => {
        return false;
      });
      if (cantidad != undefined)
        productPromo += Number(cantidad);

    }


    return true;



  }
  async revisarPromo4() {
    this.spinner.show();
    this.revisarPromo4Core();
    this.spinner.hide();
  }


}
