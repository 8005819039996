import { Injectable } from '@angular/core';
import { HttpService } from './../http/http.service';
import { Order } from 'src/app/classes/order';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CheckoutService {


  constructor(
    private httpService:  HttpService
  ) {

  }

  makeOrder(order: any) : Promise<any> {
    return this.httpService.post('orders', order);
  }

  addCustomer(id_store: string, customer: any) : Promise<any> {
    return this.httpService.post(`orders/${id_store}/addCustomer`, customer);
  }

  getCost(id_store: string, data: any) : Promise<any> {
    return this.httpService.post(`orders/cost`, data);
  }

  getInfoCEP(cep: string ) : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('cep', String(cep));

    return this.httpService.get(`home/verify`,params);
  }
  getCustomer(id_store: string, customer: any) : Promise<any> {
    return this.httpService.post(`orders/${id_store}/getCustomer`, customer);
  }


  getSeller(id:any,id_store:any){

    return this.httpService.get(`home/getSeller/${id_store}/${id}`);
  }

}
