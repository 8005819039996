<div class="producto" style="width:{{width}}%">
    <div class="img-producto" (click)="irAProducto()" #productDiv (window:resize)="0" [ngStyle]="{'height.px': productDiv.offsetWidth }">
        <div *ngIf="promoActive" class="promolabelactive">
            {{promotionInfo.name}}
        </div>
        <div *ngIf="product.images.length>0">
            <img (error)="onImageError(product.images[0])" class="img-fluid" [src]="product.images[0].url">
        </div>
        <div *ngIf="product.images.length==0">
            <img class="img-fluid" src="https://i.imgur.com/ipMEqUS.jpg">
        </div>
    </div>
    <div class="informacionProducto">
        <div class="titleProduct">
            {{product.name}}
        </div>
        <div class="priceProduct">
            {{product.variations[0].price | brazilPipe }}
            <div (click)="irAProducto()" class="svg" [inlineSVG]="'/assets/imgs/svg/lupa.svg'"></div>
        </div>
        <!-- <div class="iconProduct" (click)="addItemTocart()">
            <div class="svg" [inlineSVG]="'/assets/imgs/svg/cart.svg'"></div>
        </div>-->
    </div>
</div>
