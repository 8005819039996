import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Product } from 'src/app/classes/product';
import { ProductsService } from 'src/app/services/products/products.service';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { CartService } from './../../services/cart/cart.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input()
  product:any;
  @Input()
  promotion:any;
  @Input()
  width:any;
  @Input()
  hover:any=true;
  public promotionInfo : any =[];
  public id_store_current = "";
  public promoActive: any = false;
  tienefoto:boolean = false;
  current_product: any = {};
  constructor(private router: Router,
    private porductService: ProductsService,
    private PromotionsServices: PromotionsServices,
    private StoresService:StoresService,
    private cartService: CartService,
    ) { 
      this.StoresService.getStoreId()
      .subscribe((id : any) => {
     if(id!="null" && id!=null)       this.id_store_current=id;
      });
 
  }

  ngOnInit(){

    this.tienefoto = false;

    if(this.product.images.lenght > 0)
    {
      this.tienefoto = true;
    }

    let _this = this;
    this.PromotionsServices.getPromotion()
    .subscribe((promotion : any) => {
      if(promotion){
        this.promotionInfo = promotion;
        if(this.promotionInfo&&this.promotionInfo!=false && (this.promotionInfo.type=='004' || this.promotionInfo.type=='002' || this.promotionInfo.type=='005' )){
          this.PromotionsServices.isProductInPromotion(this.id_store_current,this.product.sku).then((res2) => {
            if (res2.state == "success") {
                if(res2.data.length!=0){
                  _this.promoActive=  true;
                    }else{
                      _this.promoActive=  false;
                    }
                  }else{
                    _this.promoActive=  false;
                }
          }).catch((error) => {
            _this.promoActive=  false;
            });
       }
      }
 
    }); 
   


  }

  irAProducto()
  {
    this.router.navigateByUrl(`/${this.id_store_current}/product/${this.product.sku}`);
  }

  addItemTocart()
  {

    let productTemp = new Product(this.product);
    var varTemp:any = {};

      if(productTemp.variations.length > 1)
      {
       productTemp.variations = productTemp.variations.splice(1, productTemp.variations.length);
        varTemp=this.setVariation(productTemp,productTemp.variations[1],1);
      }
      else {
       varTemp=this.setVariation(productTemp,productTemp.variations[0],0);
      }

     this.cartService.addItemTocart(productTemp,  varTemp, 1);
  }

  setVariation(product:any,variation: any, index: number)
  {
    let current_product: any = Object.assign({}, variation);
    current_product.description = (variation.description != undefined && variation.description != null) ? variation.description : null;
    current_product.price = variation.price;
    current_product.image = (product.images[index] != undefined) ?  product.images[index].url : 'https://i.imgur.com/ipMEqUS.jpg';
    current_product.cod = `${variation.cod}`;
    current_product.sku = `${variation.sku}`;
    return current_product;
  }

  onImageError(entity: any):void {
    entity.url = 'https://admincolombia.asesoraspandora.com/assets/img/logo_share.jpg'
  }

}
