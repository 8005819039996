import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeServices {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }


  getHome() : Promise<any> {
    return this.httpService.get(`home/getHome`);
  }
  getLanding() : Promise<any> {
    return this.httpService.get(`home/getHomeTwo`);
  }


  getCatalogo(){
    return this.httpService.get('home/getCatalogo?id_client='+environment.CLIENTE)
  }

}
