<div class="container cart" *ngIf="store">
    <div class="">
        <div class="row noPadding ">
            <div class="col-12 col-md-12 col-xl-8">
                <div class="list-tag">
                    <div class="tag tag0" *ngIf="promotionActive()">
                        <div class="tag-title">
                            Regalos de promoción <i class="fas fa-check" *ngIf="promoElegida()"></i>
                        </div>
                        <div class="tag-info promotion-tag">

                            <div class="product-list">

                                <div class="product" *ngFor="let item of listPromo1; index as i" (click)="abrirPromo(2,i)">
                                    <div class="row">
                                        <div class="product-img" #prodList (window:resize)="0" [ngStyle]="{'height.px': prodList.offsetWidth }">
                                            <img class="img-fluid" [src]="(item[2]!=-1)?item[3]:item[3]">
                                        </div>
                                        <div class="product-title">
                                            {{item[0]}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tag tag1">
                        <div class="tag-title" (click)="changeStep(1)">
                            Datos personales <i class="fas fa-check" *ngIf="step>1"></i>

                        </div>

                        <div class="tag-info" *ngIf="step==1">
                            <div class="row-line">
                                <div class="container-input container75">
                                    <input placeholder="Email" type="email" name="email" class="input-tag" required [(ngModel)]="orderForm.customer.email" #email="ngModel" (key.enter)="checkMail()" (change)="checkMail()" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

                                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                                        <br>
                                        <div *ngIf="email.errors.required" class="alert alert-danger" role="alert">
                                            El Email es obligatorio
                                        </div>
                                        <div *ngIf="email.errors?.pattern" class="alert alert-danger" role="alert">
                                            Este no es un Email valido
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input container50">
                                    <input placeholder="Nombre" class="input-tag" required [(ngModel)]="orderForm.customer.name" #firstname="ngModel">

                                    <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                                        <br>
                                        <div *ngIf="firstname.errors.required" class="alert alert-danger" role="alert">
                                            Nombre es obligatorio
                                        </div>

                                    </div>
                                </div>
                                <div class="container-input container50">
                                    <input placeholder="Apellido" class="input-tag" required [(ngModel)]="orderForm.customer.lastname" #lastname="ngModel">

                                    <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)">
                                        <br>
                                        <div *ngIf="lastname.errors.required" class="alert alert-danger" role="alert">
                                            Apellido es obligatorio
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input container50">
                                    <input placeholder="NIT" class="input-tag" required [(ngModel)]="orderForm.customer.cpf" #cpf="ngModel">
                                    <div *ngIf="cpf.invalid && (cpf.dirty || cpf.touched)">
                                        <br>
                                        <div *ngIf="cpf.errors.required" class="alert alert-danger" role="alert">
                                            NIT es obligatorio
                                        </div>


                                    </div>
                                </div>
                                <div class="container-input container50">
                                    <input placeholder="Telefono" class="input-tag" [(ngModel)]="orderForm.customer.phone" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" #phone="ngModel" required>
                                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                                        <br>
                                        <div *ngIf="phone.errors.required" class="alert alert-danger" role="alert">
                                            Telefono es obligatoria
                                        </div>
                                        <div *ngIf="phone.errors.pattern" class="alert alert-danger" role="alert">
                                            Solamente son permitidos numeros.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input checkbox-especial">
                                    <div class="control-group">
                                        <label class="control control-checkbox text-tyc">
                                            He leído y entendido la política de tratamiento de datos de  PANDORA a cargo de Stones Rocks & Metals S.A., y en tal sentido, AUTORIZO el envío de información de la marca Pandora, a través de email y otros medios.<br><a href="assets/imgs/POLITICA_DE_PROTECCION_DE_DATOS_PERSONALES_RUA_&_JEWELRY_SAS.pdf" target="_blank" download="POLITICA_DE_PROTECCION_DE_DATOS_PERSONALES_RUA_&_JEWELRY_SAS.pdf">Más información.</a>
                                                <input type="checkbox" name="checkbox" value="1" checked="checked" [(ngModel)]="tyc" />
                                            <div class="control_indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input container50 checkbox-especial">
                                    <div class="control-group">
                                        <label class="control control-radio" *ngIf="store.has_delivery == 1 ">
                                            Envío
                                                <input type="radio" name="radio" value="1" checked="checked" [(ngModel)]="modoEntrega" />
                                            <div class="control_indicator"></div>
                                        </label>
                                        <label class="control control-radio text-nowrap" *ngIf="store.pickup == 1">
                                            Recoger en Tienda
                                                <input type="radio" name="radio" value="2"  [(ngModel)]="modoEntrega" />
                                            <div class="control_indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="botones-tag">
                                <div class="btn-type" *ngIf="modoEntrega==1" (click)="goToDeliveryData()">
                                    Dirección
                                </div>
                                <div class="btn-type" *ngIf="modoEntrega==2" (click)="goToPaymentDataPick()">
                                    Pagar
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tag tag2" *ngIf="store.has_delivery && modoEntrega==1">
                        <div class="tag-title">
                            Entrega <i class="fas fa-check" *ngIf="step>2"></i>
                        </div>
                        <div class="tag-info" *ngIf="step==2">
                            <div class="row-line">

                                <div class="container-input container50">
                                    <input placeholder="Provincia" class="input-tag" [(ngModel)]="deliveryAddress.state" #state="ngModel" required>
                                    <div *ngIf="state.invalid && (state.dirty || state.touched)">
                                        <br>
                                        <div *ngIf="state.errors.required" class="alert alert-danger" role="alert">
                                            Provincia es obligatoria
                                        </div>

                                    </div>
                                </div>

                                <div class="container-input container50">
                                    <input placeholder="Cantón" class="input-tag" [(ngModel)]="deliveryAddress.city" #city="ngModel" required>
                                    <div *ngIf="city.invalid && (city.dirty || city.touched)">
                                        <br>
                                        <div *ngIf="city.errors.required" class="alert alert-danger" role="alert">
                                            Cantón es obligatoria
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="row-line">

                                <div class="container-input container50">
                                    <input placeholder="Distrito" class="input-tag" [(ngModel)]="deliveryAddress.endereco" #endereco="ngModel" required>
                                    <div *ngIf="endereco.invalid && (endereco.dirty || endereco.touched)">
                                        <br>
                                        <div *ngIf="endereco.errors.required" class="alert alert-danger" role="alert">
                                            Distrito es obligatoria
                                        </div>

                                    </div>
                                </div>

                                <div class="container-input container50">
                                    <input placeholder="Barrio" class="input-tag" [(ngModel)]="deliveryAddress.neighborhood" #neighborhood="ngModel" required>
                                    <div *ngIf="neighborhood.invalid && (neighborhood.dirty || neighborhood.touched)">
                                        <br>
                                        <div *ngIf="neighborhood.errors.required" class="alert alert-danger" role="alert">
                                            Barrio es obligatorio
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="row-line">
                                <div class="container-input container100">
                                    <input placeholder="Otras señas" class="input-tag" [(ngModel)]="deliveryAddress.street" #street="ngModel" required>

                                    <div *ngIf="street.invalid && (street.dirty || street.touched)">
                                        <br>
                                        <div *ngIf="street.errors.required" class="alert alert-danger" role="alert">
                                            Otras señas es obligatoria
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="row-line">
                                <div class="container-input container50">
                                    <input placeholder="Destinatário" class="input-tag" [(ngModel)]="deliveryAddress.destinatario" #destinatario="ngModel" required>
                                    <div *ngIf="destinatario.invalid && (destinatario.dirty || destinatario.touched)">
                                        <br>
                                        <div *ngIf="destinatario.errors.required" class="alert alert-danger" role="alert">
                                            Destinatario es obligatorio
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div class="botones-tag">

                                <div class="btn-type" (click)="goToPaymentData()">
                                    Pagar
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="tag" *ngIf="modoEntrega==2">
                        <div class="tag-title">
                            Dirección de la tienda <i class="fas fa-check"></i>
                        </div>
                        <div class="tag-info">

                            <div class="row-line text-left">
                                <label>{{store.cep}} , {{store.city}}</label>
                            </div>
                            <div class="row-line text-left">
                                <label>{{store.address}}</label>
                            </div>
                            <div class="row-line text-left">
                                <label>{{store.phone}}</label>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-12 col-md-12 col-xl-4 ">
                <div class="cont-carrito">
                    <div class="cart-title">
                        <div class="cart-title-label">
                            Mi Pedido
                        </div>


                    </div>
                    <div class="product-list">
                        <div class="product" *ngFor="let productCart of cart; index as i">
                            <div class="row">
                                <div class="product-img" #prodList (window:resize)="0" [ngStyle]="{'height.px': prodList.offsetWidth }">
                                    <img class="img-fluid" [src]="productCart.variation.image">
                                </div>
                                <div class="product-title">
                                    {{productCart.product.name}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="text-right" style="width:100%">
                                    {{productCart.variation.quantity}} x {{productCart.variation.price| brazilPipe}}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-total bgRose bgGr" *ngIf="Promo1>0">
                        <div class="cart-info2  ">
                            <div class="cart-total-label">
                                <b>A cada {{cant_1| brazilPipe}}</b>
                            </div>
                        </div>
                    </div>

                    <div class="product-list" *ngIf="Promo1>0">
                        <div class="product" *ngFor="let item of listPromo1; index as i" (click)="abrirPromo(2,i)">
                            <div class="row">
                                <div class="product-img" #prodList (window:resize)="0" [ngStyle]="{'height.px': prodList.offsetWidth }">
                                    <img class="img-fluid" [src]="(item[2]!=-1)?item[3]:item[3]">
                                </div>
                                <div class="product-title">
                                    {{item[0]}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="cart-total bgRose bgGr">
                        <div class="cart-info2 bgRose bgGr" *ngIf="confirmFlete==true">
                            <div class="cart-total-label">
                                Envio
                            </div>
                            <div class="cart-total-mount" *ngIf="envio <= 0">
                                Gratis
                            </div>
                            <div class="cart-total-mount" *ngIf="envio > 0">
                                {{envio| brazilPipe}}
                            </div>
                        </div>
                    </div>
                    <div class="cart-total bgRose">
                        <div class="cart-info bgRose">
                            <div class="cart-total-label">
                                Total
                            </div>
                            <div class="cart-total-mount">
                                {{(cartTotal + envio)| brazilPipe}}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template *ngIf="modalThanks">

    <div class="modal fade show" style="display: block;" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered ">
            <div class="modal-content modal-1">
                <div class="modal-body modal-2">
                    <div class="text1">
                        Gracias por su compra!
                        <div class="texto2">
                            Pago realizado satisfactoriamente

                            <div class="text-center" *ngIf="modoEntrega==2">
                                <br>
                                <b>Dirección de la tienda:</b><br> {{store.cep}} , {{store.city}}<br> {{store.address}}
                                <br> {{store.phone}}
                            </div>

                            <div class="cerrar-btn" (click)="irAHome()">
                                Cerrar
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</ng-template>

<div *ngIf="modalGift">

    <div class="modal fade show" style="display: block;" tabindex="-1" aria-labelledby="exampleModalLabel"  aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered "  >
            <div class="modal-content modal-1">
                <div class="modal-body modal-2">
                    <div class="" *ngIf="modalGiftType==1">
                        <h3>¿Que obsequio desea?</h3>
                        <div class="control-group checkbox-especial" style="display:flex;">

                            <label class="control control-radio">
                                Joyero
                                    <input type="radio" name="ModalType1Opcion" value="1" checked="checked" [(ngModel)]="modalType1Opcion" />
                                <div class="control_indicator"></div>
                            </label>
                            <label class="control control-radio">
                                Brazalete
                                    <input type="radio" name="ModalType1Opcion" value="2"  [(ngModel)]="modalType1Opcion" />
                                <div class="control_indicator"></div>
                            </label>
                        </div>
                    </div>
                    <div class="" *ngIf="modalGiftType==2">
                        <h3>Selecione un modelo de Brazalete</h3>
                    </div>
                    <div class="list" *ngIf="showProductList()">
                        <input placeholder="Ingresar nombre ... " type="text" id="roundText" class="form-control round" [(ngModel)]="searchQuery"  (keyup)="searchUpdate($event)" >

                        <div class="product-list" *ngIf="promotionInfo !=null" (scroll)="scrollTablet($event)">
                            <div class="product" *ngFor="let item of productsPromo; index as i">
                                <div class="row" *ngIf="item!=null">
                                    <div style="display:contents;">
                                        <div class="product-img" #prodList (window:resize)="0" [ngStyle]="{'height.px': prodList.offsetWidth }">
                                            <img class="img-fluid" [src]="item.images[0].url">
                                        </div>
                                        <div class="product-title">
                                            {{item.name}}<br>
                                            <div class="product-size">
                                                <div style="display: inherit;" *ngFor="let variation of item.variations;  index as j">
                                                    <div (click)="selectBracelete(i,j)" *ngIf="candSelect(i,j)" [ngClass]="(temporalSelect[0]==i && temporalSelect[1]==j)?'value active':'value'">
                                                        {{variation.cod}}
                                                    </div>

                                                    <div *ngIf="!candSelect(i,j) && (variation.stock>0)" class="value disabled">
                                                      {{variation.cod}}
                                                      <div class="line"></div>
                                                    </div>
                                                </div>

                                                <div *ngIf="!showIfStock(item.variations)" style="display: initial!important;" class="value disabled">
                                                    No hay stock disponible
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div *ngIf="productsPromo.length==0">
                                <div class="product">
                                    <br>
                                     <h3>No se han encontrado brazaletes en la promoción</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cerrar-btn" (click)="AdicionarObsequio()">
                        Agregar
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" (click)="this.modalGift=false" ></div>
</div>
