import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-pop-up-politicas',
  templateUrl: './pop-up-politicas.component.html',
  styleUrls: ['./pop-up-politicas.component.scss']
})
export class PopUpPoliticasComponent implements OnInit {
  public showPopUp:boolean=true;
  public id_store_current="";
  constructor(
    private router: Router, private StoresService:StoresService,
  ) {
    this.StoresService.getStoreId()
    .subscribe((id : any) => {
   if(id!="null" && id!=null)       this.id_store_current=id;
    });
   }

  ngOnInit(): void {
        if(localStorage.getItem('politica') == null){
          localStorage.setItem('politica',"false");
      }else{
          let localStr:any=localStorage.getItem('politica');

          if(localStr!="true"){
            this.showPopUp=true;
          }else{
            this.showPopUp=false;
          }
      }
         
  }
  politicalOk(){
    this.showPopUp=false;
    localStorage.setItem('politica',"true");
    //
  }

  goToPoliticas(){
    this.router.navigateByUrl(`/${this.id_store_current}/politicas`);
  }
  

}
