<div class="container  cart" *ngIf="store">
    <div class="">
        <div class="row noPadding ">
            <div class="col-12 col-md-12 col-xl-12">
                <div class="list-tag">
                    <div class="tag tag1">
                        <div class="tag-title" >
                            Detalles de reserva <i class="fas fa-check" *ngIf="step>1"></i>

                        </div>

                        <div class="tag-info" *ngIf="step==1">

                            <div class="row-line">
                                <div class="container-input container50">
                                    <input placeholder="Nombre" class="input-tag" required [(ngModel)]="orderForm.customer.name" #firstname="ngModel">

                                    <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">
                                        <br>
                                        <div *ngIf="firstname.errors.required" class="alert alert-danger" role="alert">
                                          Nombre requerido.
                                        </div>

                                    </div>
                                </div>
                                <div class="container-input container50">
                                    <input placeholder="Apellido" class="input-tag" required [(ngModel)]="orderForm.customer.lastname" #lastname="ngModel">

                                    <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)">
                                        <br>
                                        <div *ngIf="lastname.errors.required" class="alert alert-danger" role="alert">
                                          Apellido requerido.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input container75">
                                    <input placeholder="Email" type="email" name="email" class="input-tag" required [(ngModel)]="orderForm.customer.email" #email="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

                                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                                        <br>
                                        <div *ngIf="email.errors.required" class="alert alert-danger" role="alert">
                                            Email es requerido.
                                        </div>
                                        <div *ngIf="email.errors?.pattern" class="alert alert-danger" role="alert">
                                            Email invalido
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div class="row-line">
                                <div class="container-input container50">
                                    <input placeholder="Telefono" class="input-tag" [(ngModel)]="orderForm.customer.phone" pattern="^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$" #phone="ngModel" required>
                                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                                        <br>
                                        <div *ngIf="phone.errors.required" class="alert alert-danger" role="alert">
                                            Telefono es requerido.
                                        </div>
                                        <div *ngIf="phone.errors.pattern" class="alert alert-danger" role="alert">
                                              Solamente números permitidos.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="botones-tag">
                                <div class="btn-type" (click)="makeOrder()">
                                    Enviar
                                </div>
                            </div>
                        </div>

                    </div>


                    Utilizamos cookies para mejorar la experiencia del usuario y analizar la frecuencia del sitio web. Por estos motivos, podemos compartir los datos que nos ha proporcionado con nuestros socios de análisis web. Al hacer clic en "Acepto cookies", acepta el almacenamiento de todas las tecnologías descritas en nuestra Política de cookies en su dispositivo. Puede cambiar la configuración de las cookies en cualquier momento haciendo clic en "Preferencias de cookies"
                  </div>

            </div>

        </div>
    </div>
</div>

<ng-template *ngIf="modalThanks">

    <div class="modal fade show" style="display: block;" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered ">
            <div class="modal-content modal-1">
                <div class="modal-body modal-2">
                    <div class="text1">
                        Gracias por su reserva.
                        <div class="texto2">
                            Reserva realizada con exito!

                            <div class="text-center" *ngIf="modoEntrega==2">
                                <br>
                                <b>Direccion de la tienda:</b><br> {{store.cep}} , {{store.city}}<br> {{store.address}}
                                <br> {{store.phone}}
                            </div>
                            <a *ngIf="promotionInfo" href={{reservationInfo.url}}>
                            <div class="cerrar-btn">
                                Cerrar
                            </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</ng-template>

