import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/app/classes/product';
import { HomeServices } from 'src/app/services/home/home.service';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { CartService } from './../../services/cart/cart.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  public promotionInfo: any = [];
  public id_store_current = "";
  public cart = [];
  constructor(
    private PromotionsServices: PromotionsServices,
    private spinner: SpinnerService,
    private cartService: CartService,
    private StoresService: StoresService,
    private router: Router
  ) {
    this.StoresService.getStoreId()
      .subscribe((id: any) => {
        if (id != "null" && id != null) {
          this.id_store_current = id;
          this.PromotionsServices.getPromotion()
            .subscribe((promotion: any) => {
              console.log("promp in check")
              this.promotionInfo = promotion;

            });
        }

      });


    this.cartService.getCart()
      .subscribe((cart: any) => {
        this.cart = cart;

      });

  }

  ngOnInit(): void {

    if (this.cart.length == 0) {
      this.router.navigateByUrl('/' + this.id_store_current);
    }


  }

}
