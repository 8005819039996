// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // CLIENTE:"pandorabolivia",
  // CLIENTE:"pandoraguatemala",
// CLIENTE:"pandoracostarica",
 //API_URL: "https://apipartners.byflexy.com/api/v1/",
 //API_URL: "http://apipandora.byflexy.com/api/v1/",
//  API_URL: "https://apimexico.asesoraspandora.com/api/v1/",

API_URL: "https://apicostarica.asesoraspandora.com/api/v1/",
// API_URL: "https://apipartners.byflexy.com/api/v1/",
CLIENTE:"pandoracostarica",
//API_URL: "https://apicolombia.asesoraspandora.com/api/v1/",
//API_URL: "https://apicostarica.asesoraspandora.com/api/v1/",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
