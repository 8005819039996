 <div *ngIf="!promotionInfo">

  <app-checkout000></app-checkout000>
</div>


<div *ngIf="promotionInfo && promotionInfo.type=='001'">
  <app-checkout001 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='001'"></app-checkout001>
</div>

<div *ngIf="promotionInfo && promotionInfo.type=='002'">

  <app-checkout002 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    ></app-checkout002>
</div>
<div *ngIf="promotionInfo && promotionInfo.type=='003'">
  <app-checkout003 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='003'"></app-checkout003>
</div>


<div *ngIf="promotionInfo && promotionInfo.type=='004'">
  <app-checkout004 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='004'"></app-checkout004>
</div>

<div *ngIf="promotionInfo && promotionInfo.type=='005'">
  <app-checkout005 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='005'"></app-checkout005>
</div>
<div *ngIf="promotionInfo && promotionInfo.type=='006'">
  <app-checkout006 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='006'"></app-checkout006>
</div>
<div *ngIf="promotionInfo && promotionInfo.type=='007'">
  <app-checkout007 [id_store_current]="id_store_current" [promotionInfo]="promotionInfo"
    *ngIf="promotionInfo && promotionInfo.type=='007'"></app-checkout007>
</div>
