
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private httpService : HttpService
  ) { }

  getList(id_store: string ) : Promise<any> {
    return this.httpService.get(`categories/${id_store}/list`)
  }

  getMaterialsList() : Promise<any> {
    return this.httpService.get(`materials/list`)
  }


  getThemesList() : Promise<any> {
    return this.httpService.get(`themes/list`)
  }

  checkStore(id: string ) : Promise<any> {
    return this.httpService.get(`categories/${id}/detail`);
  }

}
