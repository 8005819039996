<div class="container metodos-pago">
    <div class="d-md-flex d-block justify-content-between">
        <div class="text-center">
            <p class="m-0 p-0">Tu pago está protegido por:</p>
            <img src="/assets/imgs/pcj2.png" title="Pago protegido" alt="Pago protegido" class="img-fluid">
        </div>
        <div class="text-center">
            <p class="m-0 p-0">Pasarela de pago:</p>
            <!--<a href="https://wompi.co/" target="_blank" rel="nofollow"><img src="/assets/imgs/migo.png" title="Método paro Migo" alt="Método paro Migo" class="img-fluid"></a>-->
            <img src="/assets/imgs/migo.png" title="Método paro Migo" alt="Método paro Migo" class="img-fluid">
        </div>
    </div>
</div>
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="text-md-left text-center">
                    <img src="/assets/imgs/logo_flexy.png" alt="" class="img-fluid"><br>
                    <!--<p class="m-0">Copyright © {{currentDate | date: 'yyyy'}} FlexyStore. All Rights Reserved. <a href="javascript:void()" rel="noopener noreferrer"  (click)="modalTyC = !modalTyC">Términos y Condiciones</a></p>-->
                    <p class="m-0">Copyright © {{currentDate | date: 'yyyy'}} FlexyStore. All Rights Reserved. <a href="javascript:void()" rel="noopener noreferrer" href="/assets/imgs/VENTAS_TELEFONICAS_Y_POR_CANALES_DIGITALES_A_TRAVES_DE_SOLUCIONES_TECNOLOGICAS.pdf"
                            target="_blank" download="VENTAS_TELEFONICAS_Y_POR_CANALES_DIGITALES_A_TRAVES_DE_SOLUCIONES_TECNOLOGICAS.pdf">Términos y Condiciones</a></p>
                </div>
            </div>

            <div class="col-md-5 text-md-right text-center align-self-end">
                <a href="mailto:contacto@flexystore.com" target="_blank">contacto@flexystore.com</a>

            </div>

        </div>
    </div>
</div>
<div *ngIf="modalTyC" id="modal-terminos">
    <div class="modal fade show" style="display: block;" id="modalTerminos" tabindex="-1" aria-labelledby="modalTerminosLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered ">
            <div class="modal-content modal-1">
                <div class="modal-header">
                    <h5 class="modal-title">Términos y Condiciones</h5>
                    <button type="button" class="close" (click)="modalTyC = !modalTyC">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body modal-2">
                    <div class="text1">
                        En las compras realizadas del 26 de diciembre de 2020 al 31 de enero de 2021, al comprar 5 productos o más en referencias seleccionadas aplicaremos un descuento del 50% del valor puntual de tu compra en las tiendas PANDORA a cargo de Stones Rocks & Metals
                        S.A. El descuento se aplicará al valor total de la factura. Disponible solo en tiendas PANDORA en Costa Rica a cargo de Stones Rocks & Metals S.A. La selección e inventario de productos en general (incluidos aquellos objeto de
                        la presente promoción) puede variar por tienda. La oferta aplica para estilos seleccionados. Promoción válida solo al momento de la compra. Aplica solo un bono por comprador durante las fechas establecidas. Promoción valida al
                        momento de la compra, no intercambiable por dinero en efectivo, no aplican compras con bono de regalo. Consulta los detalles con un asesor comercial de la tienda. Las imágenes publicadas en este aviso son ilustrativas y ambientadas
                        con fines publicitarios, el producto e inventario puede variar sin previo aviso y puede no ser idéntico al de la imagen. Mercancía en promoción NO tiene cambio. Stones Rocks & Metals S.A no se responsabiliza por los daños causados
                        por el uso inadecuado del producto. Aplica únicamente una promoción por compra, en caso que hubiese algún beneficio más favorable, el cliente podrá no aceptar este descuento y solicitar que se aplique la otra promoción vigente.
                        Adicionalmente, en caso que para dicho momento Stones Rocks & Metals S.A. tenga joyeros en inventario, al comprar 5 o más productos seleccionados, el consumidor tendrá la posibilidad de adquirir uno de estos joyeros al precio de
                        US$ 250.00.

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show" (click)="modalTyC = !modalTyC"></div>
</div>
