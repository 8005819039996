import { CartService } from './../../services/cart/cart.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from 'src/app/services/share/share.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Product } from 'src/app/classes/product';
import { MetaService } from 'ng2-meta';
import Swal from 'sweetalert2';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { StoresService } from 'src/app/services/stores/stores.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public cart: any[] = [];
  indiceFoto: number = 0;
  TotalCart: number = 0;
  url: string = '';
  private mostrarspinner = true;
  public id_store_current = "";
  public sales_price_products = [];
  promotionInfo: any = [];
  public promoActive: any = false;
  public promotionActive: any = false;
  constructor(
    private router: Router,
    private shareServices: ShareService,
    private porductService: ProductsService,
    private route: ActivatedRoute,
    private PromotionsServices: PromotionsServices,
    private metaService: MetaService,
    private StoresService: StoresService,
    private spinner: NgxSpinnerService,
    private cartService: CartService) {
    this.StoresService.getStoreId()
      .subscribe((id: any) => {
        if (id != "null" && id != null) this.id_store_current = id;
      });
    this.spinner.hide();
    this.route.params.subscribe(params => {

      this.cartService.getCart()
        .subscribe((cart: any) => {
          this.cart = cart;
          this.actualizarTotal();

          this.sales_price_products = Array.from(Array(this.cart.length), (_, i) => 0);

        });
      let _this = this;
      if (params['code'] !== undefined) {

        this.shareServices.getShareByUserSlug(params['code']).then(respuesta => {
          this.spinner.show();
          this.cartService.clear();
          localStorage.setItem('user_reference', respuesta.data.id_user);
          let jsonData = JSON.parse(respuesta.data.json);
          jsonData = JSON.parse(jsonData);
          let products = jsonData.products;

          for (var i = 0; i < products.length; i++) {
            let _ii = i;
            let tope = products.length;
            
            let cantidad = products[i].cantidad;
            let productTemp = products[i].product;
           
            this.porductService.getProductBySku(productTemp.sku, respuesta.data.id_store).then(respuesta2 => {
              let t_product = respuesta2.data;
              var varTemp: any = {};
              let aux: any[] = [];
              t_product.variations = t_product.variations;


              varTemp = this.setVariation(t_product, productTemp.variations[0], 0);

              this.cartService.addItemTocart(t_product, varTemp, cantidad);
        
              varTemp = [];
              if (_ii == tope - 1) {
                _this.spinner.hide();
              }
              // console.log(this.product);
            })
            // if(this.promotionInfo&&this.promotionInfo!=false && (this.promotionInfo.type=='004')){
            //   setTimeout(() => {
            //     this.revisarPromo4Core();
            //   }, 150);
            // }

            /////////////////

          }
        })
      }
      this.PromotionsServices.getPromotion()
        .subscribe((promotion: any) => {
          if (promotion) {
            console.log("promotion",promotion);
            this.promotionInfo = promotion;
          
            if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '002')) {
              setTimeout(() => {
                this.revisarPromo2Core();
              }, 150);
            }

            if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004')) {
              setTimeout(() => {
                this.revisarPromo4Core();

              }, 150);
            }
            if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '005')) {
              setTimeout(() => {
                this.revisarPromo5Core();

              }, 150);
            }

          }
        });

    });

  }

  ngOnInit() {
    this.sales_price_products = Array.from(Array(this.cart.length), (_, i) => 0);

    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004'||this.promotionInfo.type == '002' ||this.promotionInfo.type == '005')) {
      for (var i = 0; i < this.cart.length; i++) {
        let __cart = this.cart[i];
        let __it = i;
        this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

          if (res2.state == "success") {

            if (res2.data.length != 0) {
              let productos_promos = res2.data;
              this.sales_price_products[__it] = 0;
              for (var j = 0; j < productos_promos.length; j++) {
                if (__cart.variation.cod == productos_promos[j].cod) {

                  this.sales_price_products[__it] = productos_promos[j].sale_price;
                }
              }
            }


          } else {
            this.promoActive = false;
          }
        }).catch((error) => {
          this.promoActive = false;
        });
      }

    }


  }
  setVariation(product: any, variation: any, index: number) {


    let current_product: any = Object.assign({}, variation);
    current_product.description = (variation.description != undefined && variation.description != null) ? variation.description : null;
    current_product.price = variation.price;
    current_product.image = (product.images[index] != undefined) ? product.images[index].url : 'https://i.imgur.com/ipMEqUS.jpg';

    current_product.cod = `${variation.cod}`;
    current_product.sku = `${variation.sku}`;
 

    return current_product;
  }

  addCantidad(index) {
    for (var i = 0; i < this.cart[index].product.variations.length; i++) {
      if (this.cart[index].product.variations[i].cod == this.cart[index].variation.cod) {
        if (this.cart[index].product.variations[i].stock >= this.cart[index].variation.quantity + 1) {
          this.cart[index].variation.quantity++;
          this.cartService.updateCart(this.cart);

          if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004')) {

            setTimeout(() => {
              this.revisarPromo4();
            }, 100);
          }
          if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '002')) {

            setTimeout(() => {
              this.revisarPromo2Core();
            }, 100);
          }
          if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '005')) {

            setTimeout(() => {
              this.revisarPromo5Core();
            }, 100);
          }
          this.actualizarTotal();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No se puede completar su orden.'
          });
        }
      }
    }


  }
  changeVariation(index, idx) {

    this.cart[index].variation.cod = this.cart[index].product.variations[idx].cod;

    this.cart[index].variation.sku = this.cart[index].product.variations[idx].sku;
    this.cart[index].variation.price = this.cart[index].product.variations[idx].price;

    this.cartService.updateCart(this.cart);
    this.actualizarTotal();
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004')) {
      setTimeout(() => {
        this.revisarPromo4();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '002')) {
      setTimeout(() => {
        this.revisarPromo2Core();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '005')) {
      setTimeout(() => {
        this.revisarPromo5Core();
      }, 150);
    }
  }
  removeCantidad(index) {
    this.cart[index].variation.quantity--;
    if (this.cart[index].variation.quantity == 0) {
      this.removeItem(index);
    }
    this.cartService.updateCart(this.cart);
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004')) {
      setTimeout(() => {
        this.revisarPromo4();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '002')) {
      setTimeout(() => {
        this.revisarPromo2Core();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '005')) {
      setTimeout(() => {
        this.revisarPromo5Core();
      }, 150);
    }
    this.actualizarTotal();
  }

  removeItem(index) {
    this.cart.splice(index, 1);
    this.sales_price_products.splice(index, 1);
    this.cartService.updateCart(this.cart);
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '004')) {
      setTimeout(() => {
        this.revisarPromo4();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '002')) {
      setTimeout(() => {
        this.revisarPromo2Core();
      }, 150);
    }
    if (this.promotionInfo && this.promotionInfo != false && (this.promotionInfo.type == '005')) {
      setTimeout(() => {
        this.revisarPromo5Core();
      }, 150);
    }
    this.actualizarTotal();
  }

  actualizarTotal() {
    if (this.cart.length > 0) {
      this.TotalCart = this.cart.map((item, index) => {
        let valor = 0;

        if (this.promotionInfo != false && (this.promotionInfo.type == '004' ||this.promotionInfo.type == '002') && this.promotionActive && this.sales_price_products[index] > 0) {
          valor = item.variation.quantity * this.sales_price_products[index];
        } else  if (this.promotionInfo != false && (this.promotionInfo.type == '005') && this.promotionActive && 0 > this.sales_price_products[index]) {
          valor = (item.variation.quantity * item.variation.price) + this.sales_price_products[index];
        }else {
          valor = item.variation.quantity * item.variation.price;
        }
        return valor;

      }).reduce((x, y) => x + y);
    } else {
      this.TotalCart = 0;
    }


  }

  irACheckout() {
    if (this.TotalCart > 0) {
      for (var i = 0; i < this.cart.length; i++) {
        for (var j = 0; j < this.cart[i].product.variations.length; j++) {

          if (this.cart[i].variation.cod == this.cart[i].product.variations[j].cod) {

            if (this.cart[i].product.variations[j].stock == 0 || (this.cart[i].variation.quantity > this.cart[i].product.variations[j].stock)) {

              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Se debe seleccionar un tamaño '
              });
              return;
            }
          }
        }
      }
      this.router.navigateByUrl(`/${this.id_store_current}/checkout`);
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: 'El carrito esta vacio'
      })
    }

  }
  irAHome() {
    this.router.navigateByUrl(`/${this.id_store_current}`);
  }


  async revisarPromo4() {
    this.spinner.show();
    this.revisarPromo4Core();
    this.spinner.hide();

  }

  async revisarPromo5Core() {
    this.sales_price_products = Array.from(Array(this.cart.length), (_, i) => 0);
    let productPromo = 0; // Cantidad de productos de la promocion.
    let cntProducts = 0;
    let products_promo=[];
    for (var i = 0; i < this.cart.length; i++) {
      let producto_en_promo = 0;
      let __cart = this.cart[i];
      let __it = i;
      let cantidad = await this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

        if (res2.state == "success") {

          if (res2.data.length != 0) {
            let productos_promos = res2.data;
            for (var j = 0; j < productos_promos.length; j++) {
              this.sales_price_products[__it] = 0;
              if (__cart.variation.cod == productos_promos[j].cod) {
                
                for (var c = 0; c < __cart.variation.quantity; c++) {
                products_promo.push({position:__it,product:__cart.variation,price:__cart.variation.price});
                }
                return __cart.variation.quantity;
              }

            }
          }
        }
      }).catch((error) => {
        return false;
      });
      if (cantidad != undefined)
        productPromo += Number(cantidad);

    }
    products_promo=products_promo.sort(this.menorPrice);
    var freeProducts = Math.floor(products_promo.length / 3);
    for (var c = 0; c < freeProducts; c++) {
      this.sales_price_products[products_promo[c].position]-=products_promo[c].price;
      }
    console.log('productos_promo',products_promo);
    if (freeProducts>0) {
      this.promotionActive = true;
    } else {
      this.promotionActive = false;
    }
    this.actualizarTotal();
  }

  async revisarPromo4Core() {


    let productPromo = 0;
    let cntProducts = 5;

    for (var i = 0; i < this.cart.length; i++) {
      let producto_en_promo = 0;
      let __cart = this.cart[i];
      let __it = i;
      let cantidad = await this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

        if (res2.state == "success") {

          if (res2.data.length != 0) {
            let productos_promos = res2.data;
            for (var j = 0; j < productos_promos.length; j++) {
              this.sales_price_products[__it] = 0;
              if (__cart.variation.cod == productos_promos[j].cod) {
                this.sales_price_products[__it] = productos_promos[j].sale_price;
                return __cart.variation.quantity;
              }

            }
          }
        }
      }).catch((error) => {
        return false;
      });
      if (cantidad != undefined)
        productPromo += Number(cantidad);

    }
    if (productPromo >= cntProducts) {
      this.promotionActive = true;
    } else {
      this.promotionActive = false;
    }
    this.actualizarTotal();
  }

  async revisarPromo2Core() {

    let productPromo = 0;

    for (var i = 0; i < this.cart.length; i++) {
      let producto_en_promo = 0;
      let __cart = this.cart[i];
      let __it = i;

      let cantidad = await this.PromotionsServices.isProductInPromotion(this.id_store_current, this.cart[i].product.sku).then((res2) => {

        if (res2.state == "success") {

          if (res2.data.length != 0) {
            let productos_promos = res2.data;
            for (var j = 0; j < productos_promos.length; j++) {
              this.sales_price_products[__it] = 0;
              if (__cart.variation.cod == productos_promos[j].cod) {
                console.log("product ",productos_promos[j])
                this.sales_price_products[__it] = productos_promos[j].sale_price;
                return __cart.variation.quantity;
              }
            }
          }
        }
      }).catch((error) => {
        return false;
      });
      if (cantidad != undefined)
        productPromo += Number(cantidad);
      this.promotionActive = true;
      this.actualizarTotal();

    }

  }
  menorPrice( a, b ) {
    if ( a.price  < b.price ){
      return -1;
    }
    if ( a.price >  b.price ){
      return 1;
    }
    return 0;
  }

}
