import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  getListPage(page: number = 1,search:any  ="") : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`products/list`,params)

  }

  getProductBySku(sku: string, id_store : string ) : Promise<any> {
    return this.httpService.get(`products/${sku}/detail/${id_store}`);
  }
  getListCategory(id_tienda: string ,category:any,page:any="10") : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page));

    return this.httpService.get2(`products/listCategories/${id_tienda}/${category}`,params);
  }
  // trashProduchBySku(sku: string ) : Promise<any> {
  //   return this.httpService.put(`products/${sku}/trash`);
  // }

  // restoreProductTrashBySku(sku: string ) : Promise<any> {
  //   return this.httpService.put(`products/${sku}/restore`);
  // }

}
