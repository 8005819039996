import { Image } from '../../classes/image';
import { HostListener,Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { Product } from 'src/app/classes/product';
import { ProductsService } from 'src/app/services/products/products.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { CartService } from '../../services/cart/cart.service';
import { ActivatedRoute,ParamMap } from '@angular/router';
import { Router } from '@angular/router';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit {
  products: Product[];
  desc : string = '';
  public tienda:string ="";
  public listFinish=false;
  public pageActualList:number=1;
  public pageSearch :number=1;
  public id_store_current="";
  titleCategory:string='';
  constructor(
    private productService: ProductsService,
    private router: Router,
    private StoresService:StoresService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private PromotionsServices: PromotionsServices,
    private cartService: CartService
    ) {
      this.StoresService.getStoreId()
      .subscribe((id : any) => {
     if(id!="null" && id!=null)     
   
       {
        this.id_store_current=id;
        this.pageActualList=1;
        this.pageSearch=1;       
        this.listFinish=false;
        this.tienda = this.id_store_current;
         if((route.url as any).value[0].path=='promotion'){
          this.titleCategory ="Promociones";
          this.traerPromociones();
         }else{
          this.route.paramMap.subscribe((params: ParamMap) => {
      
  
        
                this.titleCategory = this.route.snapshot.paramMap.get("category").replace("-"," ");
                this.traerProductos();
            
          });
         }
       }
      });

  
    }
    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll($event) {

      if (
        ((window.innerHeight+  window.pageYOffset) / document.body.scrollHeight) > 0.7
        ) {
          if((this.route.url as any).value[0].path=='promotion'){
        
             this.findNextPromo();
          }else{
            this.findNext();
          }
      }
    }

    ngOnInit(): void
    {

    }


    traerProductos() {
      this.products = [];
      let _this = this;
      this.spinner.show();
      this.productService.getListCategory(this.tienda,this.route.snapshot.paramMap.get("category"),this.pageSearch)
      .then(respuesta =>{
        
        this.products = respuesta.data.data;
        if(respuesta.data.current_page == respuesta.data.last_page){

          this.listFinish=true;

       }else{
        _this.pageActualList= _this.pageSearch;
       }
        this.spinner.hide();
      }).catch((error) => {
        this.spinner.hide();
      });
    }

    traerPromociones() {
      this.products = [];
      let _this = this;
      this.spinner.show();
      this.PromotionsServices.getListProducts(this.tienda,this.pageSearch)
      .then(respuesta =>{

        this.products = respuesta.data.data;
        if(respuesta.data.current_page == respuesta.data.last_page){
       
          this.listFinish=true;

       }else{
        _this.pageActualList= _this.pageSearch;
       }
        this.spinner.hide();
      }).catch((error) => {
        this.spinner.hide();
      });
    }

    findNext(){

      if(!this.listFinish && this.pageSearch==this.pageActualList)
      {    
        let _this = this;
        this.spinner.show();
        this.pageSearch = this.pageActualList+1;
        this.productService.getListCategory( this.tienda,this.route.snapshot.paramMap.get("category"), this.pageSearch)
        .then(rest =>{


          this.products=this.products.concat(rest.data.data);
          if(rest.data.current_page == rest.data.last_page){

             this.listFinish=true;

          }else{
            _this.pageActualList= _this.pageSearch;
          }
          this.spinner.hide();
        }).catch((error) => {
          this.spinner.hide();
        });

      }

      }
      
    findNextPromo(){

      if(!this.listFinish && this.pageSearch==this.pageActualList)
      {    
        let _this = this;
        this.spinner.show();

        this.pageSearch = this.pageActualList+1;
        this.PromotionsServices.getListProducts(this.tienda,this.pageSearch)
        .then(rest =>{

          this.products=this.products.concat(rest.data.data);
          if(rest.data.current_page == rest.data.last_page){
        
             this.listFinish=true;

          }else{
            _this.pageActualList= _this.pageSearch;
          }
          this.spinner.hide();
        }).catch((error) => {
          this.spinner.hide();
        });

      }

      }

}
