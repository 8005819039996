<div class="container  product" *ngIf="product">
    <div class="container-products">
        <div *ngIf="productoCargado" class="row noPadding ">
            <div class="col-12  col-sm-12 col-md-6  col-lg-6  col-xl-6">

                <div class="product-gallery">
                    <div class="principal-image bgGrey">
                        <div *ngIf="promoActive" class="promolabelactive">
                            {{promotionProduct.name}}
                        </div>
                        <img class="img-fluid" [src]="current_product.image">
                    </div>


                </div>
            </div>
            <div class="col-12 col-sm-12  col-md-6  col-lg-6  col-xl-6 ">
                <div class="row detailsProducts">
                    <h5>
                        <span *ngIf="product.categories.length>0"> {{product.categories[0].category.name}}</span>
                        <span class="float-right"><a href="javascript: window.history.go(-1)" class="volver">X</a></span>
                    </h5>
                    <h2>
                        <!-- Anel em Pandora Rose Flores Abertas Rosas -->
                        {{product.name}}
                    </h2>
                    <h4>
                        {{current_product.product_sku}}
                    </h4>

                    <div class="product-quantity">
                        <div class="label">
                            Cantidad
                        </div>
                        <div class="value">
                            <div class="cantidad">
                                <div class="arrow1" *ngIf="itemCantidad>1" (click)="removeCantidad()">
                                    <i class=" fas fa-minus"></i>
                                </div>
                                <div class="numero">
                                    {{itemCantidad}}
                                </div>
                                <div class="arrow2">
                                    <i class="fas fa-plus" (click)="addCantidad()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-size" *ngIf="product.variations">
                        <div class="label">
                            Tamaño
                        </div>
                        <!-- <div class="value active">
                            58
                        </div>
                      -->
                        <div *ngFor="let variation of product.variations, let i = index">
                            <div *ngIf="variation.stock>=itemCantidad && variation.stock>0 " [ngClass]="(variation.cod==current_product.cod)?'value active':'value'" (click)="setVariation(variation, i)">
                                {{variation.cod}}
                            </div>

                            <div *ngIf="!(variation.stock>=itemCantidad && variation.stock>0)" class="value disabled">
                                {{variation.cod}}
                                <div class="line"></div>
                            </div>

                        </div>
                        <!-- div class="value disabled">
                            58
                            <div class="line"></div>
                        </div>
                        -->

                    </div>
                    <div class="product-price" *ngIf="salePrice!=0">
                        <span class="sale-price"> {{current_product.price| brazilPipe}} </span> {{salePrice| brazilPipe}}

                    </div>
                    <div class="product-price" *ngIf="salePrice==0">
                        {{current_product.price| brazilPipe}}
                    </div>
                    <div class="product-botons">
                        <div class="btn-cart" (click)="addItemTocart()">
                            Agregar al carrito
                            <div class="cartSvg" [inlineSVG]="'/assets/imgs/svg/cart.svg'"></div>
                            <div class="carro_agregado" [@changeDivDimension]=currentState>
                                <img src="./assets/imgs/thumbs-up-regular.svg" class="img-fluid" alt="">
                            </div>
                        </div>
                        <div class="btn-comprar" (click)="buy()">
                            Comprar
                        </div>
                    </div>

                    <div class="product-description">
                        <h5>Descripción</h5>
                        <div class="description">
                            <p>
                                {{current_product.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
