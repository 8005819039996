import { CommonComponent } from './views/common/common.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';

import { LayoutNoMenuComponent } from './layout/layout-no-menu/layout-no-menu.component';
import { HomeComponent } from './views/home/home.component';
import { CartComponent } from './views/cart/cart.component';
import { ProductPageComponent } from './views/product-page/product-page.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { CheckoutPaymentComponent } from './views/checkoutpayment/checkoutpayment.component';
import { CategoryPageComponent } from './views/category-page/category-page.component';
import { PoliticasComponent } from './views/politicas/politicas.component';
import { ReservationComponent } from './views/reservation/reservation.component';

const routes: Routes = [
  { path: '', component: CommonComponent },
  {
    path: ':id/reservation',
    component: LayoutNoMenuComponent,
    children: [
      { path: '', component: ReservationComponent },
      { path: ':code', component: ReservationComponent },
    ]
  },
  {
    path: ':id',
    component: MainLayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'politicas', component: PoliticasComponent },
      { path: 'cart', component: CartComponent },
      { path: 'cart/:code', component: CartComponent },
      { path: 'product', component: ProductPageComponent },
      { path: 'product/:id', component: ProductPageComponent },
      { path: 'product/:id/:code', component: ProductPageComponent },
      { path: 'promotion', component: CategoryPageComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'checkout/:status/:code', component: CheckoutPaymentComponent },
      { path: ':category', component: CategoryPageComponent },
      { path: ':category/:subcategory', component: ProductPageComponent },   
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: "reload"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
