
import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { Order, IOrder } from 'src/app/classes/order';
import { CheckoutService } from 'src/app/services/checkout/checkout.service';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { BrazilPipePipe } from '../../pipes/brazil-pipe.pipe';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { IStore } from 'src/app/classes/store';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Product } from 'src/app/classes/product';
import { ReservationService } from 'src/app/services/reservation/reservation.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  public step: any = 1;
  public minValDelivery=899;
  public sendProducts:any=[];
  public reservationInfo:any=[];
  public modalThanks: boolean = false;
  /********************** LOGIC PROMO */
  public mountGift=1399;
  public promotionInfo: any = [];
  public showProductListPromotion: boolean = true;
  public modalGiftType: number = 0;
  public modalGift: boolean = false;
  public modalType1Opcion: string = "1";
  public temporalSelect: any = [-1, -1];
  public temporalIndex: number = -1;
  public Promo1: number = 0;
  public Promo2: number = 0;
  public listPromo1: any = [];
  public listPromo2: any = [];


  /******************************* */
  public confirmFlete: boolean = false;
  public numberCard: any = "";
  public modoEntrega: any = "1";
  public validCard: boolean = false;
  public insertedCard: any = "";
  public cardList: any = ['visa', 'master', 'diners', 'american', 'discover', 'hipercard', 'elo', 'jcb'];
  public cart: any;
  public cartTotal: any;
  public envio: number = 0;

  public cardMonth: string = '12';
  public cardYear: string = '2021';

  public years: any[] = [];
  public months: any[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  public quotas: any[] = [];
  public estados: string[] = ['AC', 'AL', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];
  public orderForm: any = {
    id_store: '',
    customer: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
      cpf: '12345678909',
    },

  };
  public id_store_current="";
  public store = JSON.parse(localStorage.getItem("store"));

  constructor(
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private PromotionsServices: PromotionsServices,
    private spinner: SpinnerService,
    private BrazilPipePipe: BrazilPipePipe,
    private StoresService:StoresService,
    private route: ActivatedRoute,
    private ReservationService : ReservationService,
    private router: Router
  ) {
    
    this.StoresService.getStoreId()
    .subscribe((id : any) => {
   if(id!="null" && id!=null)       this.id_store_current=id;
    });
    this.spinner.hide();
    this.cartService.getCart()
      .subscribe((cart: any) => {
        this.cart = cart;
      });

 
    this.cartService.getCartTotal()
      .subscribe((cartTotal: any) => {
        this.cartTotal = cartTotal;
        let aux = Math.floor(this.cartTotal / 100);

        if (aux <= 1) {
          aux = 1;
        }
        else if (aux >= 10) {
          aux = 10;
        }

        this.quotas = [];
        for (let i = 1; i <= aux; ++i) {
          this.quotas.push(i);
        }
      });

    let currentYear = new Date().getFullYear()
    for (let i = currentYear; i <= (currentYear + 20); ++i) {
      this.years.push(String(i));
    }

    this.store = JSON.parse(localStorage.getItem('store'));
    this.route.params.subscribe(params => {

      if(params['code'] !== undefined){
    //    this.spinner.show();
        this.ReservationService.getShareByUserSlug(params['code'] ).then(respuesta => {
          if(respuesta.data== null){
            this.router.navigateByUrl(`/`);
          }
          this.cartService.clear();
          localStorage.setItem('user_reference',respuesta.data.id_user);
         let jsonData=JSON.parse(respuesta.data.json);
             jsonData=JSON.parse(jsonData);
             this.reservationInfo=respuesta.data;
             this.reservationInfo.url=jsonData.urlretorno;
   


      
     
        })
      }else
      {
          this.router.navigateByUrl(`/`); 
      }

    });

  }

  ngOnInit(): void {

  
    if (!(this.promotionInfo == null)) {
      let resto = this.cartTotal % 1399;

      this.Promo2 = (this.cartTotal - resto) / 1399;
      for (let i = 1; i <= this.Promo2; ++i) {
        this.listPromo2.push(['Escolha 01 bracelete de presente + 01 porta joia ', 'SKU', '-1', "https://i.imgur.com/ipMEqUS.jpg"]);
      }

      if (resto > 1199) {
        this.Promo1 = 1;

        this.listPromo1.push(['Escolha 01 bracelete de presente o 01 porta joia ', 'SKU', '-1', "https://i.imgur.com/ipMEqUS.jpg"]);
      }
    }
  }
  setVariation(product:any,variation: any, index: number)
  {
    let current_product: any = Object.assign({}, variation);
    current_product.description = (variation.description != undefined && variation.description != null) ? variation.description : null;
    current_product.price = variation.price;
    current_product.image = (product.images[index] != undefined) ?  product.images[index].url : 'https://i.imgur.com/ipMEqUS.jpg';

    return current_product;
  }




  makeOrder(): any {
    if (this.validatePersonalData()) {
    this.orderForm.id_store = this.id_store_current;
    if (!this.orderForm.id_store) {
      return false;
    }

    if(!(localStorage.getItem('user_reference') == null)){
       this.orderForm.id_seller=localStorage.getItem('user_reference');
    }
    this.orderForm.id_reservation = this.reservationInfo.id;
    let order = new Order(this.orderForm);
    this.spinner.show();
    this.ReservationService.makeOrder(order,this.orderForm.id_store,this.reservationInfo.id)
      .then((response) => {
        if (response.state == "success") {
          this.checkoutService.getSeller(localStorage.getItem('user_reference'), this.store.id)
            .then((resp: any) => {
              this.spinner.hide();
              if (typeof resp.data === 'string' || resp.data instanceof String) {
                this.modalThanks = true;
/*
                var mensaje = "Acabei de fazer um pedido.. \n\n*ORDER*: " + resp.data + "\n\n";
                this.cart.forEach(c => {
                  mensaje += `*Nome*: ${c.product.name}\n*Sku*: ${c.product.sku}\n${c.variation.quantity} x ${this.BrazilPipePipe.transform(c.variation.price)}\n\n`;
                });
                mensaje += `*Total*: ${this.BrazilPipePipe.transform(this.cartTotal)}\n\n`;

                var popup = window.open('https://api.whatsapp.com/send?phone=' +
                  resp.data + '&text=' +
                  encodeURIComponent(mensaje), 'Geolocalizacion', 'width=' + screen.width + ',height=' + screen.height + ',toolbar=0,resizable=1,scrollbars=no');
                this.cartService.clear();*/
              }
              else {
                //     alert("Atualmente não há pessoal disponível");
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'havia um problema, entre em contato com um agente Pandora'
                })
              }
            });
        } else {
          this.spinner.hide();
          if (response.data.code >= 300 && response.data.code <= 304) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Produto indisponível em estoque.'
            })
          }
        }
      }).catch((error) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocorreu um problema ao processar seu pedido, entre em contato com um consultor Pandora.'
        })
      });
    }
    return;
  }

  private validatePersonalData(): boolean {

    if (!this.orderForm.customer.name) {

      return false;
    }

    if (!this.orderForm.customer.lastname) {
      return false;
    }

    if (!this.orderForm.customer.email) {
      return false;
    } else if (!this.validateEmail(this.orderForm.customer.email)) {
      this.alertFail("O email inserido não é válido");
      return false;
    }

    if (!this.orderForm.customer.phone) {
      return false;
    }

    return true;
  }
  
  alertFail(message: string = "Ocorreu um problema, verifique os dados", title: string = "Oops...",) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: message
    })
  }

  
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

 
  irAHome() {
    this.modalThanks = false;
    document.location.href = this.promotionInfo.url;

  }

}
