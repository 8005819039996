import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart/cart.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-checkoutpayment',
  templateUrl: './checkoutpayment.component.html',
  styleUrls: ['./checkoutpayment.component.scss']
})
export class CheckoutPaymentComponent implements OnInit {
  public valid :boolean = true;
  public modalThanks :boolean = true;
  public status:string ="";
  public id_store_current="";
  constructor(
    private spinner: SpinnerService,
    private CartService:CartService,
    private router: Router,
    private StoresService:StoresService,
    private route: ActivatedRoute,
    ) {
      this.StoresService.getStoreId()
      .subscribe((id : any) => {
     if(id!="null" && id!=null)       this.id_store_current=id;
      });
      this.route.params.subscribe(params => {
        console.log(params);
        if(params['status'] !== undefined && params['code'] !== undefined ){
          this.status=params['status'];
     
        }else{
              this.router.navigateByUrl(`/`);
              return false;
        }
    });
  }

  ngOnInit(): void {
    
    if(this.status !="approved" &&
    this.status !="reject" &&
    this.status !="expired" &&
    this.status !="fail"
    ){
      this.router.navigateByUrl(`/`);
    }   

    if(this.status =="approved" ){
      this.CartService.clear();
    }
  }

  irAHome() {
 
    this.router.navigateByUrl(`/${this.id_store_current}`);
  }
  irAlCarrito() {

    this.router.navigateByUrl(`/${this.id_store_current}/cart`);
  }
  statePetition(index:any){

    if(index==1 && this.status==='approved')
    return true;   
  }
}
