import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { OlMapComponent } from '../ol-map/ol-map.component';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import * as Proj from 'ol/proj';

export const DEFAULT_LAT = -34.603490361131385;
export const DEFAULT_LON = -58.382037891217465;
export const DEFAULT_ANCHOR = [1, 1];
export const DEFAULT_MARKERS = [{lat: DEFAULT_LAT, lon: DEFAULT_LON}];
export const DEFAULT_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAyVBMVEUAAADnTDznTDvnTDvnTDvAOCrnTDznSzvnTDvAOCvnTDznTDznTDvnTDzAOCrnTDvnTDvnTDvnTDznTDvAOSrnTDznTDzTQjLSQjPnTDzpTDvnSzvAOCrnTDvAOSvAOCvnSzvnTDzAOCvnSzznTDznTDvnTDy/OCvnTDznTDvnTDznSzvmSzvAOCvnTDzAOCvnTDvmTDvAOCq+OCrpTDzkSzrbRjbWRDTMPi+8NinrTT3EOy3gSDjTQjPPQDLHPS/DOiu5NCjHPC5jSfbDAAAAMHRSTlMAKPgE4hr8CfPy4NzUt7SxlnpaVlRPIhYPLgLt6ebOysXAwLmej4iGgGtpYkpAPCBw95QiAAAB50lEQVQ4y42T13aDMAxAbVb2TrO6927lwQhktf//UZWVQ1sIJLnwwBEXWZYwy1Lh/buG5TXu+rzC9nByDQCCbrg+KdUmLUsgW08IqzUp9rgDf5Ds8CJv1KS3mNL3fbGlOdr1Kh1AtFgs15vke7kQGpDO7pYGtJgfbRSxiXxaf7AjgsFfy1/WPu0r73WpwGiu1Fn78bF9JpWKUBTQzYlNQIK5lDcuQ9wbKeeBiTWz3vgUv44TpS4njJhcKpXEuMzpOCN+VE2FmPA9jbxjSrOf6kdG7FvYmkBJ6aYRV0oVYIusfkZ8xeHpUMna+LeYmlShxkG+Zv8GyohLf6aRzzRj9t+YVgWaX1IO08hQyi9tapxmB3huxJUp8q/EVYzB89wQr0y/FwqrHLqoDWsoLsxQr1iWNxp1iCnlRbt9IdELwfDGcrSMKJbGxLx4LenTFsszFSYehwl6aCZhTNPnO6LdBYOGYBVFqwAfDF27+CQIvLUGrTU9lpyFBw9yeA+sCNsRkJ5WQjg2K+QFcrywEjoCBHVpe3VYGZyk9NQCLxXte/jHvc1K4XXKSNQ520PPtIhcr8f2MXPShNiavTyn4jM7wK0g75YdYgTE6KA465nN9GbsILwhoMHZETx53hM7Brtet9lRDAYFwR80rG+sfAnbpQAAAABJRU5ErkJggg==';

@Component({
  selector: 'ol-map-marker',
  templateUrl: './ol-map-marker.component.html',
  styleUrls: ['./ol-map-marker.component.css']
})
export class OlMapMarkerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() icon: string = DEFAULT_ICON;
  @Input() anchor: number[] = DEFAULT_ANCHOR;
  @Input() markers: any[];

  vectorSource: VectorSource;
  vectorLayer: VectorLayer;
  private _markers = [];

  constructor(private olMap: OlMapComponent) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this._markers = [];
    this.markers.forEach(m => {
      const icon = new Style({
        image: new Icon({
          anchor: this.anchor,
          src: this.icon
        })
      });

      let marker: any = new Feature({
        geometry: new Point(Proj.fromLonLat([m.lng, m.lat])),
        name: m.title,
        population: 4000,
        rainfall: 500,
      });

      marker.customData = {id_store: m.id, domain: m.domain, openInfoWindow: m.openInfoWindow, title: m.title};
      marker.setStyle(icon);
      this._markers.push(marker);
    });

    this.vectorSource = new VectorSource({
        features: this._markers
    });

    this.vectorLayer = new VectorLayer({
        source: this.vectorSource
    });

    if (this.olMap.map) {
      this.olMap.setMarker(this.vectorLayer);
    } else {
      setTimeout(() => {
        this.ngOnInit();
      }, 10);
    }

  }

  ngOnDestroy() {}

}
