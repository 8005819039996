<app-sliderhome *ngIf="dataLoad" [sliders]="datosHome.sliders[0]"></app-sliderhome>

<h2 *ngIf="dataLoad">{{datosHome.texto1}}</h2>

<div class="container container-products" *ngIf="dataLoad">
    <div class="row noPadding">
        <div *ngFor="let idx of [0,1,2,3,4,5,6,7]" class="col-md-3 col-sm-6 noPadding mb-4">
            <app-product-card [product]="datosHome.productos[idx]" [width]="95"></app-product-card>
        </div>
    </div>
</div>

<app-banner *ngIf="dataLoad" [banner]="datosHome.sliders[1]"></app-banner>


<div class="container container-products paddingEspecial" *ngIf="dataLoad">
    <div class="row noPadding">
        <div *ngFor="let idx of [8,9,10,11,12,13,14,15]" class="col-md-3 col-sm-6 noPadding mb-4">
            <app-product-card [product]="datosHome.productos[idx]" [width]="95"></app-product-card>
        </div>
    </div>
</div>
<h2 *ngIf="dataLoad">{{datosHome.texto2}}</h2>
<div *ngIf="dataLoad" class="container-fluid backImage paddingEspecial" [ngStyle]="{'background-image': getFondo()}">
    <div class="row noPadding">
        <div class="offset-sm-6 col-sm-6 col-12">
            <div class="row noPadding">
                <div class="col-6 noPadding desktop  mb-4" *ngFor="let idx of [16,17,18,19]">
                    <app-product-card [product]="datosHome.productos[idx]" [width]="95"></app-product-card>
                </div>

                <owl-carousel-o class="col-12 mobile" [options]="customOptions" id="carousel2">
                    <ng-template carouselSlide>
                        <app-product-card [product]="datosHome.productos[16]" [width]="95" [hover]="false"></app-product-card>
                    </ng-template>

                    <ng-template carouselSlide>
                        <app-product-card [product]="datosHome.productos[17]" [width]="95" [hover]="false"></app-product-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <app-product-card [product]="datosHome.productos[18]" [width]="95" [hover]="false"></app-product-card>
                    </ng-template>
                    <ng-template carouselSlide>
                        <app-product-card [product]="datosHome.productos[19]" [width]="95" [hover]="false"></app-product-card>
                    </ng-template>

                </owl-carousel-o>
            </div>
        </div>

    </div>
</div>
<div *ngIf="dataLoad" class="container container-products">
    <div class="row noPadding">
        <div class="col-12 col-md-6 ">
            <div class="row noPadding">
                <div class="col-12 noPadding paddingBotton ">
                    <img class="img-fluid" style="width:97%;" [src]="datosHome.imagenes[1].url">
                </div>
                <div class="col-sm-6 mb-md-0 mb-4 noPadding">
                    <app-product-card [product]="datosHome.productos[20]" [width]="95"></app-product-card>
                </div>
                <div class="col-sm-6 mb-md-0 mb-4 noPadding">
                    <app-product-card [product]="datosHome.productos[21]" [width]="95"></app-product-card>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 noPadding">
            <app-product-card [product]="datosHome.productos[22]" [width]="97"></app-product-card>
        </div>
    </div>
</div>