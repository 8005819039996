import { CartService } from './../../services/cart/cart.service';
import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition('initial=>final', animate('200ms', keyframes([
          style({ opacity: "1", offset: 0 }),
          style({ opacity: "0", offset: 0.50 }),
          style({ opacity: "1", offset: 1 })
        ]))),
        transition('final=>initial', animate('200ms', keyframes([
          style({ opacity: "1", offset: 0 }),
          style({ opacity: "0", offset: 0.50 }),
          style({ opacity: "1", offset: 1 })
        ])))
      ]
    )
  ]
})

export class HeaderComponent implements OnInit {

  public menu: any [] = [];
  public optionSelect:any=-1;
  public subOption:any=-1;
  public id_store_current="";
  public cartCount:number=0;
  public currentState = 'initial';
  public promotionInfo : any = false;
  constructor(
    private router: Router,
    private cartService: CartService,
    private PromotionsServices: PromotionsServices,
    private StoresService: StoresService,
    private categoryService: CategoriesService
    ) {
      this.StoresService.getStoreId()
      .subscribe((id : any) => {
        if(id!="null" && id!=null)
        {
          this.id_store_current=id;

          this.categoryService.getList(this.id_store_current)
          .then((response) => {
            let categories = response.data;
            this.categoryService.getMaterialsList()
            .then((response2) => {
              let materials = response2.data;

              this.categoryService.getThemesList()
              .then((response3) => {
                let themes = response3.data;
                let tipos = [];
                categories.forEach(category => {
                  category['submenu'] = [];
                  category['destacados']= [];
                  category['submenu'].push({title: 'Tipo', slug: 'tipo', submenu: tipos});
                  category['submenu'].push({title: 'Metais / Materiais', slug: 'metais-materiais', submenu: materials});
                  category['submenu'].push({title: 'Temas', slug: 'temas',submenu: themes});
                  category['destacados'].push({title: 'Mais desejadas', slug: 'mais-desejadas'});
                });
                this.menu = categories;
              })
              .catch((error3) => {
                console.log(error3)
              });

            })
            .catch((error2) => {
              console.log(error2)
            })
          })
          .catch((error) => {
            console.log(error)
          });

        }

         });
      this.cartService.getCartCount()
      .subscribe((cartCount : any) => {
        this.cartCount = cartCount;
        this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
      });


      this.PromotionsServices.getPromotion()
      .subscribe((promotion : any) => {
        if(promotion)
          this.promotionInfo = promotion;
      });

    }
  ngOnInit(): void {


  }
  openSubMenu(option,i,slug){
    if(window.innerWidth<1200)
     {
      this.subOption=-1;
      this.optionSelect=i;
     }
     let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
     if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
         element.click();
     }
     this.router.navigateByUrl(`/${this.id_store_current}/${slug}`);
   setTimeout(()=>{
    window.location.reload();
   },100);
  }
  closeSubMenu(option,i){
    if(window.innerWidth<1200)
     {
      this.optionSelect=-1;
      this.subOption=-1;
     }
  }
  isMobile(){
    if(window.innerWidth<1200)
      return true;
  }
  dropOptions(option,i){
    if(this.subOption==i){
      this.subOption=-1;
    }else{
      this.subOption=i;
    }
  }

  irACart()
  {
    this.router.navigateByUrl(`/${this.id_store_current}/cart`);
  }
  irGeo()
  {
    this.router.navigateByUrl(`/`);
  }
  openPromotion()
  {
    this.router.navigateByUrl(`/${this.id_store_current}/promotion`);
  }
  irAHome()
  {
    this.router.navigateByUrl(`/${this.id_store_current}`);
  }

  goToCategory(category : string, subcategory: string)
  {
    this.router.navigateByUrl(`/${this.id_store_current}/${category}/${subcategory}`);
  }
}
