<div class="container-fluid noPadding backImage" style="background-image:url('assets/imgs/bg_categoria.jpg')">
    <div class="container container-products text-center">
 
            <div class="title-category">
                <h4>Politica de privacidad:</h4>
            </div>
        
    </div>
</div>
<div class="container">
<br>
<div id="content" class="wide">
        

  <p><strong>Política de Privacidad de PANDORA</strong></p>
  <p><strong>1.&nbsp; ¿Cuál es el propósito de esta Política de Privacidad</strong></p>
  <p>PANDORA (PANDORA o nosotros) utiliza la información de sus interacciones con usted, otros clientes y ciertos terceros, para alcanzar nuestro objetivo de ofrecer la más alta calidad de productos y servicios.
  </p>
  <p>PANDORA respeta los derechos de privacidad de sus visitantes online y miembros de PANDORA Club y eStore y reconoce la importancia de proteger la información recopilada sobre ellos. Por esta razón, hemos establecido procedimientos para asegurar que su información personal se gestiona de manera responsable. Esta Política de Privacidad le proporciona información sobre qué tipo de información recopilamos, los propósitos para los los que se recopila y utiliza, cómo se gestiona, con quién puede ser compartida y qué opciones tiene usted respecto a nuestro uso de su información.
  </p>
  <p>Visitando los sitios web de PANDORA, incluyendo www.pandora.net/es-es/, usted acepta el contenido de esta Política de Privacidad junto con los Términos y Condiciones, la Política de cookies de PANDORA, y cualesquiera otros documentos mencionados en esta Política de Privacidad. Esta Política de Privacidad establece las bases sobre las cuales vamos a procesar la información que recogemos sobre usted o la que usted nos proporcione. Lea cuidadosamente lo siguiente para entender nuestras opiniones y prácticas con respecto a su información y cómo la trataremos.
  </p>
  <p>Algunas partes de esta Política de Privacidad se aplican específicamente a la recopilación y el procesamiento de sus datos personales en relación con su uso de los sitios web y servicios (p. ej. PANDORA Club y PANDORA eStore), que requieren que se registre con PANDORA para utilizar estos sitios web y servicios.
  </p>
  <p>Si tiene cualquier pregunta o preocupación sobre privacidad o desea realizar cualquier consulta sobre la información personal que nos ha proporcionado, puede ponerse en contacto con PANDORA en cualquier momento. Para saber cómo ponerse en contacto con nosotros, consulte ""Información de contacto"".
  </p>
  <p><strong>2. Quien está a cargo de la protección de su información</strong></p>
  <p>PANDORA A/S y Pandora Jewelry Chile SpA&nbsp;(PANDORA o nosotros) determina los fines y medios del tratamiento de los datos.
  </p>
  <p><strong>3. ¿Qué datos personales se recopilan?</strong></p>
  <p>Todas nuestras actividades están basadas en rigurosos principios éticos y requisitos legales, y nos comprometemos a proteger la privacidad de todos los visitantes de nuestros sitios web y de los usuarios de nuestras aplicaciones (móviles). Por esta razón, la manera en que recopilamos y almacenamos la información depende de cómo se usen nuestro sitio web y los servicios relacionados.
  </p>
  <p><strong>3.1 Datos recopilados a través de su interacción con nosotros</strong></p>
  <p>En nuestros sitios web y aplicaciones utilizamos distintas tecnologías (móviles) para mejorarlos, hacerlos más fáciles de usar, eficaces y seguros.  Estas tecnologías pueden conducir a datos recopilados automáticamente por nosotros o por terceros en nuestro nombre. Ejemplos de estas tecnologías son las cookies, las flash cookies y los análisis web.
  </p>
  <p><strong>3.1.1 Datos de click-stream </strong></p>
  <p>Cuando visita uno de nuestros sitios web, los datos se envían desde su navegador a nuestro servidor.  Estos datos nos permiten optimizar nuestros servicios y mejorar su experiencia en nuestros sitios web y aplicaciones. Los datos se recopilan y almacenan automáticamente por nosotros o por terceros en nuestro nombre.
  </p>
  <p>Podemos recopilar información acerca de su equipo para la administración del sistema y para elaborar información agregada para realizar análisis de marketing interno. Se trata de datos estadísticos sobre las acciones y los patrones de navegación de nuestros usuarios.
  </p>
  <p>En particular, estos datos pueden incluir los siguientes:</p>
  <ul>
      <li><span>la dirección IP del visitante,</span></li>
      <li><span>la fecha y hora de la visita,</span></li>
      <li><span>la URL de referencia (el sitio desde el cual proviene el visitante),</span></li>
      <li><span>las páginas visitadas en nuestro sitio web,</span></li>
      <li><span>información acerca del navegador utilizado (el tipo de navegador y versión, sistema operativo, etc.).</span></li>
  </ul>
  <p><strong>3.1.2 Cookies</strong></p>
  <p>Usamos cookies en nuestro sitio web.  Lea nuestra <a href="http://www.pandora.net/pandora-company/terms/cookie_policy">Política de Cookies</a> para averiguar más acerca de nuestro uso de las cookies y las políticas de privacidad de terceros cuyas herramientas de análisis web utilizamos o de los cuales hemos integrado plug-ins en nuestros sitios web.
  </p>
  <p><strong>3.2 Datos proporcionados por usted</strong></p>
  <p>Además de los datos recogidos por medios automatizados, también procesamos los datos que usted nos proporciona.  Esto incluye, pero no está limitado a:
  </p>
  <ul>
      <li><span>Datos personales que le identifican individualmente, a saber, información de contacto, incluyendo su nombre y apellido, dirección de entrega o facturación, fecha de nacimiento, número de teléfono, número de fax, dirección de correo electrónico e información de tarjeta de crédito y débito. Si proporciona los datos de otra persona, asegúrese de que tiene permiso para compartir esa información con nosotros,</span></li>
      <li><span>información proporcionada en el momento de registrarse en un sitio web o servicio de PANDORA (por ejemplo, PANDORA Club),</span></li>
      <li><span>información proporcionada al crear una lista de deseos o su propio diseño,</span></li>
      <li><span>información proporcionada al inscribirse en un concurso o una promoción patrocinada por el Grupo PANDORA o PANDORA A/S,</span></li>
      <li><span>la información que usted nos proporciona a través de terceros, tales como Facebook,</span></li>
      <li><span>la información que usted suba o comparta a través de nuestro sitio mediante el uso de nuestros servicios,</span></li>
      <li><span>si ha creado un perfil en línea, las preferencias e intereses almacenados en este perfil (consulte más adelante más información acerca de los perfiles),</span></li>
      <li><span>si se comunica con nosotros, es posible que llevemos un registro de esa correspondencia,</span></li>
      <li><span>si se comunica con nosotros para notificar un problema con nuestro sitio o para asistencia técnica o servicio al cliente, es posible que llevemos un registro de esa correspondencia o conversación.</span></li>
  </ul>
  <p>Es posible que también le pidamos que complete encuestas que utilizamos con fines de investigación, aunque no tiene que hacerlas.
  Todos estos datos se caracterizan por el hecho de que los proporciona usted mismo. Utilizamos esta información para los propósitos descritos en esta Política de Privacidad. Siempre podrá disponer de sus datos personales para modificarlos o procesamiento ulterior (véase apartado 6).
  </p>
  <p><strong>4. ¿Cómo se utilizan los datos recopilados?</strong></p>
  <p>Podemos utilizar la información sobre usted de las siguientes maneras:</p>
  <ul>
      <li><span>para asegurar que el contenido de nuestro sitio se presenta de la manera más eficaz para usted y para su equipo,</span></li>
      <li><span>para propósitos relacionados con su pedido; por ejemplo para: notificar el estado de su pedido o entrega, satisfacer sus peticiones, responder a una pregunta acerca de nuestros productos, ayudarle a localizar una tienda cercana a usted, realizar su pedido, procesar su tarjeta de pago y/o prestar servicio al cliente. Podemos utilizar sus datos para evaluar su solvencia, para lo cual podemos utilizar a terceros,</span></li>
      <li><span>para comprobar su identidad y responder a cualquier consulta que pueda plantearnos, así como para procesar su solicitud y ofrecerle el mejor servicio posible,</span></li>
      <li><span>cumplir con nuestras obligaciones derivadas de cualquier contrato entre usted y nosotros,</span></li>
      <li><span>para permitirle participar en funciones interactivas de nuestro servicio, cuando decida hacerlo,</span></li>
      <li><span>para notificarle los cambios en nuestros servicios o en nuestros términos, condiciones y políticas y/u otra información administrativa,</span></li>
      <li><span>proporcionarle beneficios y servicios como miembro de los sitios web de PANDORA (ej. PANDORA Club),</span></li>
      <li><span>para administrar y mantener nuestros registros,</span></li>
      <li><span>para administrar su suscripción a PANDORA, sitios web o servicios (ej. PANDORA Club),</span></li>
      <li><span>para permitirle participar en funciones interactivas de nuestro sitio si decide hacerlo,</span></li>
      <li><span>para almacenar cualquier Lista de Deseos que pueda crear y permitirle compartirla con otros,</span></li>
      <li><span>para diseñar sus propias joyas y guardar los diseños,</span></li>
      <li><span>para que pueda enviar correos electrónicos a través de funciones del tipo ""Enviar a un amigo"",</span></li>
      <li><span>para que pueda participar en sorteos, concursos y promociones similares y administrar dichas actividades. Algunas de estas actividades tienen reglas adicionales, que podrían contener más información acerca de cómo usamos y revelamos su información personal,</span></li>
      <li><span>personalizar el contenido para usted en nuestros sitios web y proporcionarle ofertas personalizadas</span></li>
      <li><span>prevenir o detectar cualquier abuso o fraude,</span></li>
      <li><span>con su consentimiento explícito, podemos ponernos en contacto con usted mediante la información de contacto proporcionada por usted (por ejemplo, por correo ordinario, correo electrónico, SMS, teléfono o cualquier otro medio electrónico) con propósitos de comercialización, publicidad y obtener su opinión. Por ejemplo, esto incluye información relacionada con los productos de PANDORA, las actividades de comercio electrónico, ofertas especiales y promociones, así como información sobre la apertura y actividades de las tiendas locales. A fin de poder ponernos en contacto con usted con la información que es de su especial interés, su interacción general con PANDORA (tales como, pero no limitado a, su comportamiento de compras en e-shops, en tiendas físicas de PANDORA, su uso de los programas de lealtad, sus valoraciones y reseñas de productos, su historial de contacto con nuestro servicio de atención al cliente, el recuento de clics/accesos al boletín, su navegación web (seguimiento), los tipos de boletín a los que está suscrito, su participación en promociones o eventos, y sus interacciones con el uso de aplicaciones móviles), se combinará, analizará y utilizará. Podemos utilizar sus datos personales con fines de comercialización, publicidad y para obtener su opinión para realizar estudios. En el capítulo 6 encontrará información sobre sus derechos, por ejemplo sobre cómo retirar su consentimiento o corregir sus datos personales.</span></li>
  </ul>
  <p>El período de uso de los datos personales facilitados por usted no superará el que consideremos necesario pero puede ser ilimitado.
  </p>
  <p><strong>5. Divulgación de la información</strong></p>
  <p>Uno de nuestros principios fundamentales es el hecho de que tratamos sus datos con cuidado y confidencialidad.  No revelaremos sus datos a terceras partes sin perjuicio de lo señalado en la presente Política de Privacidad sin su consentimiento previo. Si es requerido por ley, sus datos podrán ser cedidos a terceros. Siempre que esto esté permitido bajo la ley aplicable, podemos transferir sus datos a otras partes del grupo PANDORA.
  </p>
  <p>Podemos utilizar proveedores de servicios y procesadores de datos que trabajan en nombre de PANDORA. Los servicios pueden incluir hosting y mantenimiento, análisis, mensajería de correo electrónico, servicios de entrega, procesamiento de pagos, control de solvencia y comprobación de dirección, etc. Estos terceros tienen acceso a los datos personales necesarios para el servicio en particular. Los proveedores de servicios y procesadores de datos están obligados contractualmente a tratar dicha información con la más estricta confidencialidad. No pueden utilizar los datos de ningún otro modo que no sea el requerido. Se toman las medidas necesarias para garantizar que nuestros proveedores de servicio y los procesadores que trabajan en nombre de PANDORA protejan la confidencialidad de sus datos.
  </p>
  <p>Puede haber ocasiones en las que revelemos sus datos personales a nuestros socios en forma anónima. Tales datos no personales pueden incluir información sobre el número de visitantes a un sitio web o una aplicación (móvil) durante un cierto periodo de tiempo.
  </p>
  <p>Tenga en cuenta que cualquier información que publique o revele a través de su interacción con PANDORA (por ejemplo los datos personales contenidos en las fotos, historias, comentarios y vídeos que envíe) se convertirá en información pública y puede estar disponible para los visitantes al sitio y para el público en general.
  </p>
  <p>Es posible que revelemos su información en la medida en que estamos obligados a revelar o compartir sus datos personales con el fin de cumplir con cualquier obligación legal o las instrucciones de un juzgado o cualquier otro órgano de la jurisdicción competente, o para hacer cumplir o aplicar nuestras políticas de privacidad y otros acuerdos; o para proteger los derechos, propiedad o seguridad de PANDORA A/S o el Grupo PANDORA, nuestros empleados, nuestros clientes u otros. Esto incluye el intercambio de información con otras empresas y organizaciones para fines de protección contra fraudes y reducción del riesgo de crédito.
  </p>
  <p><strong>6. Sus derechos</strong></p>
  <p>Los datos personales que nos proporcione a través de nuestros sitios web o en otros canales, lo hace de forma totalmente voluntaria.  Si elige no proporcionar la información solicitada, pueden no estar disponible varios beneficios para el cliente . En algunos casos, solamente las personas que hayan presentado los datos personales necesarios para que podamos realizar pedidos de productos, podrán utilizar determinados servicios y disfrutar de las actividades y ofertas disponibles en nuestra página web y aplicación (móvil). Disponemos de varias opciones, dependiendo de las circunstancias exactas, a fin de ayudarle a mantener el control sobre sus datos. Estas opciones pueden incluir la visualización y modificación de los datos en línea. También puede darse de baja de servicios o eliminar cuentas de usuario y recibir información sobre los datos que contiene.
  Tiene derecho de pedir que no procesemos sus datos personales para fines de marketing. Le informaremos (antes de recopilar sus datos) de si tenemos la intención de utilizar sus datos para tales fines o divulgar su información a terceros para tales fines. Puede elegir no dar su consentimiento para dicho tratamiento no seleccionando ciertas casillas en los formularios que utilizamos para recoger sus datos. También puede ejercer este derecho en cualquier momento poniéndose en contacto con nosotros en <a href="mailto:privacy@pandora.net">privacy@pandora.net</a>&nbsp;o en la dirección postal que figura al final de esta Política de Privacidad.
  Nuestro sitio puede, eventualmente, contener enlaces hacia y desde los sitios web de nuestras redes y asociados. Si sigue un enlace a cualquiera de estos sitios web, tenga en cuenta que estos sitios web tienen sus propias políticas de privacidad y que no aceptamos ninguna responsabilidad u obligación respecto a estas políticas. Consulte estas políticas antes de enviar cualquier información personal a estos sitios web.
  </p>
  <p><strong>6.1 Derecho de oposición y darse de baja</strong></p>
  <p>Los correos electrónicos que PANDORA le envía, por ejemplo, con un boletín o contenido de marketing, incluyen la opción de darse de baja de la recepción de dichos correos electrónicos siguiendo las instrucciones pertinentes en el respectivo correo electrónico. Si no desea recibir correos electrónicos de nosotros, puede simplemente hacer clic en este enlace para darse de baja y dejaremos de enviarle mensajes de correo electrónico.
  </p>
  <p>Si no desea que se recoja y valore ninguna información sobre su comportamiento en nuestro sitio web o aplicación (móvil), puede no seleccionar la recolección de los datos de análisis web como se describe a continuación en el apartado 11.
  </p>
  <p>Si desea retirar su consentimiento para recibir información promocional y sobre ofertas en general, incluyendo correo ordinario, correo electrónico, SMS, teléfono o por cualquier otro medio electrónico, puede hacerlo en cualquier momento por escrito a PANDORA Grupo PANDORA A/S, Havneholmen 17-19, DK-1561 Copenhagen V, indicando ""protección de datos"" o enviando un correo electrónico. En caso de que tengamos alguna duda sobre su identidad, podemos pedirle que se identifique (por ejemplo, mediante el envío de una copia de su DNI o pasaporte). Aparte de los costes normales de comunicación, esto es gratis.
  </p>
  <p>Con respecto a otros tipos de mensajes que pueda recibir, como anuncios de mantenimiento o notificaciones administrativas, la recepción de estos mensajes sólo puede realizarse eliminando su cuenta ya que ese tipo de mensajes son una parte obligatoria de las cuentas de usuario y del uso de nuestros sitios web y aplicaciones (móviles). Puede eliminar su cuenta escribiendo un correo electrónico a <a href="mailto:profilesupport@pandora.net">profilesupport@pandora.net</a>.
  </p>
  <p><strong>6.2 Derecho a solicitar información</strong></p>
  <p>Usted tiene derecho de acceso a sus datos personales. Puede escribirnos (a la dirección indicada más adelante), para solicitar detalles sobre la información que tenemos sobre usted y para qué fines se mantiene.  Le proporcionaremos esta información en 40 días a partir de su solicitud, entendiendo que los procesos de rutina continúan entre ese momento y el tiempo de respuesta. Es posible que se cobre una tarifa razonable (según lo permitido por la ley de procesamiento de datos aplicable) por esta información
  </p>
  <p><strong>6.3 Derecho a rectificación</strong></p>
  <p>Usted tiene el derecho de solicitar la corrección, adición, supresión o bloqueo de los datos personales almacenados sobre usted. En las cuatro semanas siguientes a la recepción de su solicitud, le notificaremos y, en caso afirmativo, en qué medida, cumpliremos con su solicitud. Si, por cualquier razón, no cumplimos con su solicitud, le proporcionaremos las razones de ello.
  </p>
  <p><strong>7. Seguridad de la información e integridad de los datos</strong></p>
  <p>Tomamos las medidas técnicas y organizativas adecuadas para proteger los datos personales contra la destrucción accidental o ilícita, la pérdida, alteración, uso no autorizado, divulgación o acceso, en particular cuando el tratamiento incluya la transmisión de datos a través de una red, y contra cualquier otra forma ilícita de tratamiento y mal uso.
  PANDORA utiliza procesadores de datos de terceros para la recogida y tratamiento de sus datos personales. Cualquier procesador de datos de terceros contratado por PANDORA sólo realizará el procesamiento de sus datos personales de acuerdo con las instrucciones de PANDORA y estará contractualmente obligado a adherirse a estrictos procedimientos de seguridad al manejar sus datos personales.
  </p>
  <p><strong>7.1 Mantener la información segura</strong></p>
  <p>PANDORA puede emplear a terceros para recopilar información personal en nombre de PANDORA. En tales casos, estos terceros estarán sujetos a acuerdos de confidencialidad y serán instruidos por PANDORA para cumplir plenamente con la Política de Privacidad de PANDORA.
  Cuando le hayamos proporcionado (o haya elegido) una contraseña que le permita acceder a ciertas partes de nuestro sitio, usted es responsable de mantener su confidencialidad. Le pedimos que no comparta su contraseña con nadie.
  Desafortunadamente, la transmisión de información a través de Internet no es completamente segura. Aunque haremos todo lo posible para proteger sus datos personales, no podemos garantizar la seguridad de sus datos transmitidos a nuestro sitio; cualquier transmisión es a su propio riesgo. Una vez que hayamos recibido su información, utilizaremos procedimientos estrictos y funciones de seguridad para tratar de evitar el acceso no autorizado.
  </p>
  <p><strong>7.2 Acceso a sus datos personales</strong></p>
  <p>PANDORA Club:
  </p>
  <p>Puede acceder y modificar sus datos personales conectándose a su cuenta en www.pandora.net y entrando en Club. Haga clic en el botón ""My Profile"" (Mi perfil) para acceder a sus datos personales.
  Si cambia parte de la información que nos ha proporcionado, por ejemplo, si cambia su dirección de correo electrónico u otros datos de contacto, o si desea cancelar su inscripción, háganoslo saber modificando sus datos en la página ""My profile"" o enviándonos un correo electrónico mediante este enlace.
  Si tiene alguna pregunta, no dude en contactar con nosotros en <a href="mailto:profilesupport@pandora.net">profilesupport@pandora.net</a>. Si lo prefiere, también podemos modificar nosotros sus datos personales por usted. Si es así, envíe un correo electrónico a la dirección anterior o escríbanos a la dirección postal siguiente.
  </p>
  <p><strong>8. Redes sociales</strong></p>
  <p>Nuestros sitios web y aplicaciones (móviles) le proporcionarán plug-ins para diversas redes sociales. Si decide interactuar con una red social, su actividad en nuestro sitio web o a través de nuestras aplicaciones (móviles) también estará a disposición de redes sociales como Facebook y Twitter.
  </p>
  <p>Si inicia sesión en una de estas redes sociales durante su visita a uno de nuestros sitios web o aplicaciones (móviles), la red social podría añadir esta información a su perfil. Si interactúa con uno de los plug-ins sociales, esta información se transferirá al sitio de la red social. En caso de que no desee dicha transferencia de datos, cierre la sesión de la red social antes de entrar en uno de nuestros sitios web o aplicaciones (móviles).
  </p>
  <p>No podemos influir en esta obtención y transferencia de los datos a través de Plug-ins sociales. Lea las políticas de privacidad de las redes sociales para obtener información detallada sobre la obtención y transferencia de datos de carácter personal, qué derechos tiene y cómo obtener una configuración de privacidad satisfactoria.
  </p>
  <p>Además, para mejorar la experiencia de usuario y la de marca, sus datos podrán ser cedidos a un tercero (por ejemplo, Facebook) para su procesamiento de conformidad con la normativa de protección de datos y privacidad.
  </p>
  <p>Facebook: http://www.facebook.com/about/privacy/</p>
  <p>Twitter: http://twitter.com/privacy</p>
  <p>Google +: http://www.google.com/intl/en/policies/privacy/</p>
  <p><strong>9. Servicios de geolocalización</strong></p>
  <p>Ofrecemos servicios que precisan activar la locación dentro de alguna de nuestras aplicaciones (móviles), como Google Maps y Bing Maps. Si utiliza estas aplicaciones (móviles), podrá recibir información acerca de su ubicación real (señales GPS enviadas por un dispositivo móvil) o información que se pueda utilizar para llegar a una ubicación. En general, usted puede activar o desactivar los servicios de localización en el dispositivo o en la configuración del navegador.
  </p>
  <p>Utilizamos el servicio de Google Maps o Bing Maps para ayudarle a encontrar la tienda PANDORA más cercana. Para facilitar esto, cargamos una imagen de Google Maps o Bing Maps y guardamos los datos de la ruta y el mapa en nuestro servidor.
  </p>
  <p>Para más información sobre Google Maps y su política de privacidad, visite: <br>
  Google: http://www.google.com/intl/en/policies/privacy/
  </p>
  <p>Para más información sobre Bing Maps y su Política de Privacidad:<br>
  Bing Maps: http://privacy.microsoft.com/en-us/fullnotice.mspx
  </p>
  <p><strong>10. Análisis web de adobe</strong></p>
  <p>Los servicios de análisis web de  nuestro sitio web y aplicaciones (móviles) son proporcionados por adobe (www.adobe.com). Esto significa que al visitar nuestra página web o aplicaciones (móviles), una cookie de adobe se almacena en su ordenador o dispositivo móvil, excepto cuando la configuración del navegador no permita este tipo de cookies (ver Política de Cookies de PANDORA).
  </p>
  <p>Esto significa también que al visitar nuestro sitio web o aplicación (móvil), los datos de análisis web anteriormente descritos en el capítulo 2, incluyendo ""click-stream data"" (trayectoria de datos), los datos de ""web beacons y vínculos de seguimiento"" y la información almacenada en las cookies de adobe, se enviarán a adobe para que los analice en nombre y representación de PANDORA. Tenga en cuenta que si ha creado un perfil en línea en nuestro sitio web o aplicación (móvil) e inicia sesión con este perfil, un número único que identifica a este perfil también se envía a Adobe para hacer corresponder los datos de análisis web con este perfil.
  </p>
  <p>Adobe actúa como agente de PANDORA, lo que significa que es PANDORA quien determina los fines para los cuales se usan los datos y que Adobe no revelará la información a terceros (excepto cuando así lo exija la ley o una orden judicial). En términos de privacidad significa que PANDORA es el ""controlador"" y  Adobe el ""procesador"". Puede encontrar más información acerca de las relaciones entre PANDORA y adobe en la política de privacidad de Adobe en “Privacy Policy for Our Provision of Services” (Política de Privacidad de nuestra prestación de servicios) en http://www.adobe.com/uk/privacy/analytics.html .
  </p>
  <p>Si no desea que la información sobre su comportamiento en nuestro sitio web o aplicación (móvil) sea recopilada y evaluada por Adobe, puede solicitar una exclusión como visitante. Esto significa que Adobe, dependiendo del tipo de exclusión que usted elija, o bien dará un carácter anónimo a la información que recopila (pero manteniendo la recogida de los datos) o detendrá completamente la recopilación de información acerca de su visita. Este exclusión como visitante requiere que acepte una cookie que Adobe almacena en su ordenador. Usted puede solicitar la exclusión como visitante a través del enlace en la sección de política de privacidad del sitio web de Adobe.
  </p>
  <p><strong>11. Tecnologías de redirección</strong></p>
  <p>Nuestro sitio web y la aplicación (móvil) usan tecnologías de redirección.  Esto nos permite hacer publicidad directamente a nuestros visitantes, quienes ya estaban interesados en nuestra tienda y nuestros productos, desde los sitios web de nuestros socios. Creemos que la presentación de publicidad personalizada basada en preferencias es más interesante para nuestros clientes que la publicidad sin conexión personal.
  </p>
  <p>También trabajamos con otras empresas que utilizan tecnologías de seguimiento para servir anuncios en nuestro nombre a través de Internet. Estas empresas pueden recopilar información sobre sus visitas a nuestros sitios web o aplicaciones (móviles) y su interacción con nuestras comunicaciones, incluida la publicidad.
  </p>
  <p>Las tecnologías de redirección analizan sus cookies y muestran publicidad basada en su comportamiento de navegación en el pasado. Para más información sobre las cookies, consulte la sección 2 de esta política de privacidad.
  </p>
  <p><strong>12. Información de contacto</strong></p>
  <p>Si tiene alguna pregunta sobre el procesamiento de sus datos personales o sobre nuestra política de privacidad, siéntase libre de ponerse en contacto con nosotros.  Los datos de contacto son:
  </p>
  <p>Grupo PANDORA<br>
  <a href="mailto: dataprotection@pandora.net">DataProtection@Pandora.net</a></p>
  <p>Alternativamente, puede contactar con PANDORA en <br>
  PANDORA&nbsp;A/S<br>
  Havneholmen 17-19 <br>
  1561 Copenhagen V<br>
  Dinamarca<br>
  Correo electrónico: <a href="mailto:privacy@pandora.net">privacy@pandora.net</a></p>
  <p><strong>13. Cambios a esta política</strong></p>
  <p>PANDORA se ha comprometido a atenerse a los principios fundamentales de la privacidad y protección de datos.  Por lo tanto, revisamos periódicamente nuestra política de privacidad para mantenerla actualizada y compatible con los principios de protección de datos y privacidad. Esta política de privacidad puede modificarse eventualmente para mantener el ritmo de los nuevos desarrollos y oportunidades relacionadas con Internet y permanecer en línea con la legislación vigente. Los cambios significativos en la política se publican en nuestro sitio web junto con una versión actualizada de la política de privacidad.
  Cualquier cambio que realicemos en nuestra política de privacidad será publicado en esta página y, cuando proceda, podrá ser notificado por correo electrónico.
  </p>
  <p>Actualización: Noviembre de 2015
  </p>
      </div>
  </div>