import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';
import { StoresService } from 'src/app/services/stores/stores.service';
@Injectable({
  providedIn: 'root'
})
export class PromotionsServices {

  private promotion: any = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { 
  
  }

  getPromotions(store_id: string) : Promise<any> {

    return this.httpService.get(`promotions/${store_id}`)

  }

  getListProducts(store_id: string,page: number = 1,search:any  ="") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('search', String(search))
    .append('page', String(page));

    return this.httpService.get2(`promotions/${store_id}/getListProducts`,params)

  }

  getListProductsStock(store_id: string,page: number = 1,search:any  ="") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('search', String(search))
    .append('page', String(page));

    return this.httpService.get2(`promotions/${store_id}/getProductWithStockList`,params)

  }

  

  isProductInPromotion(store_id: string,sku:string) : Promise<any> {

    return this.httpService.get(`promotions/${store_id}/${sku}`)

  }

  isListProductsPromotions(store_id: string,list:any) : Promise<any> {
  
    return this.httpService.post(`promotions/${store_id}`, {products:list});

  }
  // trashProduchBySku(sku: string ) : Promise<any> {
  //   return this.httpService.put(`products/${sku}/trash`);
  // }

  // restoreProductTrashBySku(sku: string ) : Promise<any> {
  //   return this.httpService.put(`products/${sku}/restore`);
  // }
  loadPromotion(id_store:string){

      
    
      this.getPromotions(id_store)
        .then((response) => {
          console.log(response);
          if (response.state == "success") {

           if(response.data.length==0)
            {
              this.setPromotion(JSON.stringify(null));
           
            }else{

              this.setPromotion(JSON.stringify(response.data));
            }
           
          } else {
     
            this.setPromotion(JSON.stringify(null));
          }
        }).catch((error) => {
     
          this.setPromotion(JSON.stringify(null));
        });
    
  }
  
  setPromotion(promo :any){
    localStorage.setItem('promocion', promo);
    return this.promotion.next(JSON.parse(promo));
  }
  getPromotion():any{
    return this.promotion;
  }
  clear() {
    this.setPromotion([]);
  }
}
