import { Component, OnInit,Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-sliderhome',
  templateUrl: './sliderhome.component.html',
  styleUrls: ['./sliderhome.component.scss']
})
export class SliderhomeComponent implements OnInit {
  @Input()
  sliders:any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoHeight:true,
    autoplay:true,
    dots: false,
    navSpeed: 700,
    navText : ["<i class='botones fas fa-chevron-left'></i>","<i class='botones fa fa-chevron-right'></i>"],

    responsive: {
      0: {
        items: 1
      },

    },
    nav: false
  }
  constructor() { }

  ngOnInit(): void {
  }

}
