<div class="popUpInferior" *ngIf="showPopUp">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
                <h4>
                    Algunas leyes de privacidad establecidas en Costa Rica, ofrecen a sus residentes privacidad como consumidor, que respetamos conforme lo descrito en nuestras declaraciones de privacidad.
                </h4>
                <h5 (click)="goToPoliticas()">
                    Para entender mejor el uso de cookies, accede a nuestras Politica de Privacidad.
                </h5>
            </div>
            <div class="col-12 col-md-4">
                <div class="botonPoliticas" (click)="politicalOk()">
                    ACEPTAR
                </div>

            </div>

        </div>
    </div>
</div>
