import { Component, OnInit ,Input} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input()
  banner:any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay:true,
    autoHeight:true,
    dots: false,
    navSpeed: 700,
    navText : ["<i class='botones fas fa-chevron-left'></i>","<i class='botones fa fa-chevron-right'></i>"],

    responsive: {
      0: {
        items: 1
      },

    },
    nav: false
  }
  constructor() { }

  ngOnInit(): void {
  // console.log(this.banner);
  }

}
