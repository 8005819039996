import { Router } from '@angular/router';
import { Component, OnInit ,ViewChild,ElementRef, NgZone} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StoresService } from 'src/app/services/stores/stores.service';
import Swal from 'sweetalert2';
import { IStore } from 'src/app/classes/store';
import { OlMapComponent } from 'src/app/component/ol-maps/ol-map/ol-map.component';
import { HttpService } from 'src/app/services/http/http.service';
import { HomeServices } from 'src/app/services/home/home.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {
  public tiendas_cercanas =[];
  stores : IStore[] = [];
  act : boolean = false;
  customOptions: OwlOptions = {
    loop: true,
    autoWidth:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    nav: false,
    dots:false,

  }

  lat =  9.9118037;
  lng = -84.1589866;
  latTemp =  9.9118037;
  lngTemp = -84.1589866;
  zoom=13;
  address: string;
  mapType = 'satellite';
  private geoCoder;
  selectedMarker;
  markers = [
  ];
  banners =[
    {
      url:"assets/imgs/images/1.jpg",
      width:473,
      height:304,
   },
   {
      url:"assets/imgs/images/2.jpg",
      width:387,
      height:304,
    },
    {
      url:"assets/imgs/images/3.jpg",
      width:348,
      height:304,
    },
    {
      url:"assets/imgs/images/4.jpg",
      width:310,
      height:304,
    }
  ];
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('map')
  public olMap: OlMapComponent;
  public datosLanding :any =[];
  public catalogo :any;
  public catalogo_true :any=false;
  constructor(
    private storesServices : StoresService,
    private sanitizer: DomSanitizer,
    private httpService: HttpService,
    private ngZone: NgZone,
    private homeService:HomeServices,
    private router: Router
  ) {
    this.homeService.getLanding()
    .then((rest) => {

      this.datosLanding= JSON.parse(rest.data.home);

    });
    this.homeService.getCatalogo()
    .then((rest) => {
      this.catalogo = this.sanitizer.bypassSecurityTrustResourceUrl('https://heyzine.com/api1?pdf='+rest.data+'&k=0616d69b047ff397&st=0&bg=989693');
      this.catalogo_true = true;
      console.log("aca",this.catalogo);
    });
    this.storesServices.getList().then((response: any) => {
      this.stores = response.data;
     console.log(this.stores);
      for(let i=0;i<this.stores.length;i++){
        var coord=JSON.parse(this.stores[i].coordinates);
        this.markers.push({ lng: coord.lat,
           lat: coord.lng,
           alpha: 1,
            id: this.stores[i].id,
            domain:this.stores[i].domain,
            openInfoWindow:true,
            title:this.stores[i].name
           }
           )
      }
      this.tiendasCercanas();
      this.setCurrentLocation();
    })
    this.tiendasCercanas();



   }

  ngOnInit(): void {
    this.setCurrentLocation();    this.setCurrentLocation();


  }
  public searchMap(event: any)
  {

    this.searchMap2(event.target.value);
  }
  public searchMap2(text: any)
  {
    this.httpService.getExternal(`https://nominatim.openstreetmap.org/search?q=brasil+%2C+${text}&format=geojson`)
    .then((response) => {
      if(response.data.length > 0)
      {
        let coord: any = response.data[0].geometry.coordinates;
        this.olMap.setView(coord[1], coord[0]);
        this.centerChanged(coord[1],coord[0]);
      }
    });
  }
  public procesarData(data){

    this.centerChanged(data[1],data[0]);
   }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.latTemp = position.coords.latitude;
        this.lngTemp = position.coords.longitude;
        this.zoom = 14;
        this.olMap.setView(this.lat, this.lng);
        this.tiendasCercanas();
      });

    }
  }
  getAddress(latitude, longitude) {

    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 14;
          this.lat=latitude,this.lng =longitude;
          this.latTemp = latitude;
          this.lngTemp = longitude;
          this.address = results[0].formatted_address;
          this.tiendasCercanas();
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  centerChanged(lat,lng) {
    this.latTemp =lat;
    this.lngTemp = lng;
    this.tiendasCercanas();
  }
  ngAfterViewChecked(){

  }
  tiendasCercanas(){
    let distance = 20.0;
    this.tiendas_cercanas =[];
    for(let i=0;i<this.stores.length;i++){
      var coord=JSON.parse(this.stores[i].coordinates);
      let val = this.Dist(this.latTemp,this.lngTemp,coord.lng,coord.lat);
     
        this.tiendas_cercanas.push({ lng: coord.lat,
          lat: coord.lng,
          alpha: 1,
           id: this.stores[i].id,
           domain:this.stores[i].domain,
           openInfoWindow:true,
           title:this.stores[i].name,
           distance:this.Dist(this.latTemp,this.lngTemp,coord.lng,coord.lat)
          }
          );
      

    }
    this.tiendas_cercanas=this.tiendas_cercanas.sort(function(a, b){ return (a.distance-b.distance)}).slice(0,6);

  }
  openStore(store : IStore) {
    localStorage.setItem('id', store.id);
    this.router.navigateByUrl(`/${store.id}`);
  }
  addMarker(lat: number, lng: number) {
    this.markers.push({ lat, lng, alpha: 0.4 });
  }

  max(coordType: 'lat' | 'lng'): number {
    return Math.max(...this.markers.map(marker => marker[coordType]));
  }

  min(coordType: 'lat' | 'lng'): number {
    return Math.min(...this.markers.map(marker => marker[coordType]));
  }

  selectMarker(item){

    const win = window;
    this.storesServices.AddClick(item.id,'visit').then((response: any) => {

     if (typeof response.data === 'string' || response.data instanceof String){

      var mensaje="Estoy interesado en asesoria de pandora. \n*"+item.domain+"*";

      win.location.assign('https://api.whatsapp.com/send?phone=+'+response.data+'&text='+(mensaje));
      }else{

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Actualmente no hay asesoras disponibles'
      })
     }


       // this.router.navigateByUrl(`/${id}`);
    });
    }
    mouseMarkerOver(m,i,e){

    }
     rad = function(x) {
      return x * Math.PI / 180;
    }

    Dist(lat1, lon1, lat2, lon2)
    {


    var R     = 6378.137;                          //Radio de la tierra en km
    var dLat  = this.rad( lat2 - lat1 );
    var dLong = this.rad( lon2 - lon1 );

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(this.rad(lat1)) * Math.cos(this.rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = R * c;

    return parseFloat (d.toFixed(3));                      //Retorna tres decimales
  }

  processHTML(html:any){
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
