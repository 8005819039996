import { Component, OnInit, AfterViewInit, Input, ElementRef, Output,EventEmitter } from '@angular/core';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { OSM } from 'ol/source';
import * as Proj from 'ol/proj';
import * as condition from 'ol/events/condition';
import {
  defaults as defaultControls,
  Control
} from 'ol/control';
import Swal from 'sweetalert2';
import { StoresService } from 'src/app/services/stores/stores.service';
export const DEFAULT_HEIGHT = '500px';
export const DEFAULT_WIDTH = '500px';

export const DEFAULT_LAT = -34.603490361131385;
export const DEFAULT_LON = -58.382037891217465;
export const DEFAULT_MARKERS = [{lat: DEFAULT_LAT, lon: DEFAULT_LON}];

@Component({
  selector: 'ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.css']
})
export class OlMapComponent implements OnInit, AfterViewInit {
  @Input() lat: number = DEFAULT_LAT;
  @Input() lon: number = DEFAULT_LON;
  @Input() markers: any[] = DEFAULT_MARKERS;
  @Input() zoom: number;
  @Input() width: string | number = DEFAULT_WIDTH;
  @Input() height: string | number = DEFAULT_HEIGHT;
  @Output() centroMap =  new EventEmitter<any>();

  target: string = 'map-' + Math.random().toString(36).substring(2);
  map: Map;
  selectPointerMove: any;
  marker: any;
  private mapEl: HTMLElement;

  constructor(private elementRef: ElementRef,    private storesServices : StoresService,) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    let rasterLayer = new TileLayer({
      source: new OSM()
    });

    this.mapEl = this.elementRef.nativeElement.querySelector('#' + this.target);
    this.setSize();

    this.map = new Map({
      target: this.target,
      layers: [rasterLayer],
      view: new View({
        center: Proj.fromLonLat([this.lon, this.lat]),
        zoom: this.zoom
      }),
      controls: defaultControls({attribution: false, zoom: false}).extend([])
    });

    let _this = this;
    let proy = Proj;
    this.map.on('singleclick', function(e) {

      let feature: any = _this.map.forEachFeatureAtPixel(e.pixel, function(feature, layer) {
        return feature;
      });

      if (feature) {

        var coordinate = e.coordinate;
        console.log(feature);
        _this.selectMarker(feature.customData);
      }

    });

    this.map.on('pointerdrag', function() {
     
      _this.centroMap.emit(proy.transform(_this.map.getView().getCenter(),'EPSG:3857', 'EPSG:4326'));
  
    });
  
  }

  private setSize() : void {
    if (this.mapEl) {
      const styles = this.mapEl.style;
      styles.height = coerceCssPixelValue(this.height) || DEFAULT_HEIGHT;
      styles.width = coerceCssPixelValue(this.width) || DEFAULT_WIDTH;
    }
  }

  public setMarker(vector: VectorLayer) : void {
    console.log(vector);
    this.map.addLayer(vector);
  }

  public setControl(control: Control) : void {
    this.map.addControl(control);
  }

  public setZoom(number: number) : void {
    var view = this.map.getView();
    var zoom = view.getZoom();
    view.setZoom(zoom + number);
    this.map.setView(view);
  }

  public setView(lat : number, lng: number) : void {
    let view: View = new View({
      center: Proj.fromLonLat([lng, lat]),
      zoom: this.zoom
    });
    console.log(view);
    this.map.setView(view);
  }


  selectMarker(item){

    const win = window;
    this.storesServices.AddClick(item.id_store,'visit').then((response: any) => {

     if (typeof response.data === 'string' || response.data instanceof String){

      var mensaje="Estoy interesado en asesoria de pandora. \n*"+item.domain+"*";

      win.location.assign('https://api.whatsapp.com/send?phone=+'+response.data+'&text='+(mensaje));
      }else{

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Actualmente no hay asesoras disponibles'
      })
     }


       // this.router.navigateByUrl(`/${id}`);
    });
    }
 
}

const cssUnitsPattern = /([A-Za-z%]+)$/;

function coerceCssPixelValue(value: any): string {
  if (value == null) {
    return '';
  }

  return cssUnitsPattern.test(value) ? value : `${value}px`;
}
