
<div class="container-fluid noPadding">
    <owl-carousel-o [options]="customOptions" id="carouselTop">
        <ng-template *ngFor="let ban of sliders" carouselSlide>
            <div class="carousel-step">
                <img class="img-fluid" src="{{ban.url}}">
            </div>
    
        </ng-template>  
    
    </owl-carousel-o>
</div>