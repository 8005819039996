<div class="space-header">
    <div class="header">
        <div class="container">
            <div class="row">
                <div class="head">
                    <div class="logo">
                        <a  (click)="irAHome()">
                        <div [inlineSVG]="'/assets/imgs/svg/logo.svg'"></div>
                         </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
