import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';
import {PromotionsServices} from '../promotions/promotions.service';
@Injectable({
  providedIn: 'root'
})
export class StoresService {
  private id_store : BehaviorSubject<string> = new BehaviorSubject("null");
  private store : BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(
    private httpService : HttpService,private router: Router,
    private route: ActivatedRoute,
    private PromotionsServices: PromotionsServices,
  ) {

  }

  getList() : Promise<any> {
    return this.httpService.get(`stores/list`)
  }

  checkStore(id: string ) : Promise<any> {
    return this.httpService.get2(`stores/${id}/detail`);
  }
  AddClick(id: string,type:string) : Promise<any> {
    return this.httpService.post(`stores/${id}/click`, {type: type});
  }
  loadstore(id_store:string){
    this.checkStore(id_store).then((response: any) => {
      console.log(response);
      this.setStoreId((!response)?response:response.data);



      });
  }
  setStoreId(data:any){
    if(data== null ){

      this.router.navigateByUrl("/");
      this.store.next([]);
      this.id_store.next("null");
    }else{
      localStorage.setItem('store', JSON.stringify(data));
      localStorage.setItem('id',data.id);
      this.store.next(data);
      this.id_store.next(data.id);
      this.PromotionsServices.loadPromotion(data.id);

      }

  }
  getStoreId(){
    return this.id_store;
  }
  getStore(){
    return this.store;
  }
}
