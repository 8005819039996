import { Component, OnInit } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators'
import { StoresService } from 'src/app/services/stores/stores.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionsServices } from 'src/app/services/promotions/promotions.service';

declare var gtag;

@Component({
  selector: 'app-layout-no-menu',
  templateUrl: './layout-no-menu.component.html',
  styleUrls: ['./layout-no-menu.component.scss']
})
export class LayoutNoMenuComponent implements OnInit {
  ruta:string ="";
  public promotionInfo:any ="";
  
  constructor(private router: Router,   private PromotionsServices: PromotionsServices,
       private route: ActivatedRoute,    private storesServices : StoresService) {
   // this.ruta=this.id_store_current;


   const navEvents = router.events.pipe(
    filter(event => event instanceof NavigationEnd)
  )


    this.storesServices.loadstore(this.route.snapshot.paramMap.get("id"));
     
   }

   loadGoogleAnalytics(trackingID: string): void {
  /*  if (trackingID) {
      const gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);
      document.documentElement.firstChild.appendChild(gaScript);
    }
**/
  }
  ngOnInit(): void {



  }

}
