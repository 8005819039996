import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/app/classes/product';
import { HomeServices } from 'src/app/services/home/home.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

public products:Product[];
public datosHome:any=[];
public dataLoad:boolean=false;
  // public products:any =[
  //   {name:'Producto1 Collar con Cositas bonitas Producto1 Collar con Cositas bonitas',price:'1019.40',image:'assets/imgs/Layer 189 copy.png'},
  //   {name:'Producto2',price:'1019.40',image:'assets/imgs/Layer 190 copy.png'},
  //   {name:'Producto3',price:'1019.40',image:'assets/imgs/Layer 192 copy.png'},
  //   {name:'Producto4',price:'1019.40',image:'assets/imgs/Layer 191 copy.png'},
  //   {name:'Producto5',price:'1019.40',image:'assets/imgs/Layer 199 copy.png'},
  //   {name:'Producto6',price:'1019.40',image:'assets/imgs/Layer 200 copy.png'},
  //   {name:'Producto7',price:'1019.40',image:'assets/imgs/Layer 201 copy.png'},
  //   {name:'Producto8 ',price:'1019.40',image:'assets/imgs/Layer 202 copy.png'}
  // ];


  public banner:any=[
    {image:'assets/imgs/bannersale.png'}
  ]
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText : ["<i class='botones fas fa-chevron-left'></i>","<i class='botones fa fa-chevron-right'></i>"],

    responsive: {
      0: {
        items: 1
      },

    },
    nav: false
  }
  productosCargados: boolean = false;
  constructor(
    private porductService:ProductsService,
    private homeService:HomeServices
    ) {
    }

  ngOnInit(): void {



    this.productosCargados = false;
    this.homeService.getHome()
    .then((rest) => {
  
      this.datosHome= JSON.parse(rest.data.home);
      this.dataLoad=true;
    });
   // this.traerProductos();
  }


  traerProductos() {

    this.products = [];

    this.porductService.getListPage(1).then(respuesta =>{

      respuesta.data.data.forEach(item => {
        this.products.push(item);
      });
      this.productosCargados = true;



    })

  }

  getFondo(){
    return  "url('"+this.datosHome.imagenes[0].url+"')";
  }
}
