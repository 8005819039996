<div class="container-fluid noPadding backImage" style="background-image:url('assets/imgs/bg_categoria.jpg')">
    <div class="container container-products text-center">

        <div class="title-category">
            {{titleCategory}}
        </div>

    </div>
</div>
<div class="container container-products paddingEspecial">
    <div class="row noPadding">
        <div *ngFor="let prod of products" class="col-md-3 col-sm-6 noPadding mb-4">
            <app-product-card [promotion]="false" [product]="prod" [width]="95"></app-product-card>
        </div>
    </div>
</div>
