import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from '@agm/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MetaModule } from 'ng2-meta';
import { CommonModule } from '@angular/common'
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrazilPipePipe } from './pipes/brazil-pipe.pipe';

import { HomeComponent } from './views/home/home.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutNoMenuComponent } from './layout/layout-no-menu/layout-no-menu.component';
import { HeaderNoMenuComponent } from './layout/header-no-menu/header-no-menu.component';
import { SliderhomeComponent } from './views/home/sliderhome/sliderhome.component';
import { ProductCardComponent } from './component/product-card/product-card.component';
import { BannerComponent } from './component/banner/banner.component';
import { CartComponent } from './views/cart/cart.component';
import { ProductPageComponent } from './views/product-page/product-page.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { CheckoutPaymentComponent } from './views/checkoutpayment/checkoutpayment.component';

import { Checkout007Component } from './views/checkout/checkout007/checkout007.component';
import { Checkout006Component } from './views/checkout/checkout006/checkout006.component';
import { Checkout005Component } from './views/checkout/checkout005/checkout005.component';
import { Checkout004Component } from './views/checkout/checkout004/checkout004.component';
import { Checkout003Component } from './views/checkout/checkout003/checkout003.component';
import { Checkout002Component } from './views/checkout/checkout002/checkout002.component';
import  {Checkout001Component } from './views/checkout/checkout001/checkout001.component';
import { Checkout000Component } from './views/checkout/checkout000/checkout000.component';

import { FormsModule } from '@angular/forms';
import { CommonComponent } from './views/common/common.component';
import { PopUpPoliticasComponent } from './layout/pop-up-politicas/pop-up-politicas.component';
import { PoliticasComponent } from './views/politicas/politicas.component';
import { CategoryPageComponent } from './views/category-page/category-page.component';
import { ReservationComponent } from './views/reservation/reservation.component';


import { CategoriesService } from './services/categories/categories.service';
import { HomeServices } from './services/home/home.service';
import { CheckoutService } from './services/checkout/checkout.service';
import { FooterComponent } from './layout/footer/footer.component';
import { SpinnerService } from './services/spinner/spinner.service';
import { GeoService } from './services/geo/geo.service';
import { importType } from '@angular/compiler/src/output/output_ast';
import { OlMapsModule } from './component/ol-maps/ol-maps.module';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainLayoutComponent,
    HeaderComponent,
    LayoutNoMenuComponent,
    HeaderNoMenuComponent,
    SliderhomeComponent,
    BannerComponent,
    ProductCardComponent,
    CartComponent,
    ProductPageComponent,
    CheckoutComponent,
    CheckoutPaymentComponent,
    Checkout007Component,  
    Checkout006Component,
    Checkout005Component,
    Checkout004Component,
    Checkout003Component,
    Checkout001Component,
    Checkout000Component,
    Checkout002Component,
    ReservationComponent,
    CommonComponent,
    CategoryPageComponent,
    BrazilPipePipe,
    PopUpPoliticasComponent,
    PoliticasComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    CarouselModule,
    HttpClientModule,
    InlineSVGModule,
    NgxImageZoomModule,
    FormsModule,
    CommonModule,
    OlMapsModule,
    NgxSpinnerModule,
    OlMapsModule,
    MetaModule.forRoot(),
    SweetAlert2Module.forRoot()
  ],
  providers: [
    CheckoutService,
    GeoService,
    BrazilPipePipe,
    CategoriesService,
    HomeServices,
    SpinnerService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
