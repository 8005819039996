<div class="container cart">
  <div class="container-products">
    <div class="row noPadding">
      <div class="col-12 col-md-12 col-xl-8">
        <h2>Carrito</h2>
        <div class="product-cart-list">
          <div
            *ngFor="let productCart of cart; index as i"
            class="product-cart row"
          >
            <div
              class="product-cart-img col-lg-3 col-md-3"
              #productDiv
              (window:resize)="(0)"
              [ngStyle]="{ 'height.px': productDiv.offsetWidth }"
            >
              <img class="img-fluid" [src]="productCart.variation.image" />
              <div *ngIf="sales_price_products[i]!=0" class="promolabelactive">
                {{ promotionInfo.name }}
              </div>
            </div>
            <div class="product-cart-info pr-0 col-lg-8 col-md-8 col-8">
              <div class="product-cart-title">
                {{ productCart.product.name }}
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="label col text-left">Cantidad</div>
                    <div class="value col">
                      <div class="cantidad">
                        <div class="arrow1 d-inline">
                          <i
                            class="fas fa-minus"
                            (click)="removeCantidad(i)"
                          ></i>
                        </div>
                        <div class="numero d-inline">
                          {{ productCart.variation.quantity }}
                        </div>
                        <div class="arrow2 d-inline">
                          <i class="fas fa-plus" (click)="addCantidad(i)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="product-cart-price"
                    *ngIf="sales_price_products[i] > 0 && promotionActive"
                  >
                    <span class="sale-price">
                      {{ productCart.variation.price | brazilPipe }}
                    </span>
                    {{ sales_price_products[i] | brazilPipe }}
                  </div>
                  <div
                    class="product-cart-price"
                    *ngIf="sales_price_products[i] == 0 || !promotionActive"
                  >
                    {{ productCart.variation.price | brazilPipe }}
                  </div>
                  <div
                  class="product-cart-price"
                  *ngIf="0 > sales_price_products[i] && promotionActive"
                >
                  <span class="sale-price">
                    {{ sales_price_products[i] | brazilPipe }}  
                  </span>
                  {{ productCart.variation.price | brazilPipe }}
                </div>
                </div>
              </div>

              <div class="product-size">
                <div
                  style="display: inherit"
                  *ngFor="
                    let variation of productCart.product.variations;
                    index as j
                  "
                >
                  <div
                    *ngIf="
                      variation.stock >= productCart.variation.quantity &&
                      variation.stock > 0
                    "
                    id="{{ i }}{{ j }}"
                    (click)="changeVariation(i, j)"
                    [ngClass]="
                      productCart.variation.cod == variation.cod
                        ? 'value active'
                        : 'value'
                    "
                  >
                    {{ variation.cod }}
                  </div>
                  <div
                    *ngIf="
                      !(
                        variation.stock >= productCart.variation.quantity &&
                        variation.stock > 0
                      )
                    "
                    class="value disabled"
                  >
                    {{ variation.cod }}
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col p-0">
              <div class="close-product" (click)="removeItem(i)">X</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-4">
        <div class="cont-carrito">
          <div class="cart-title">
            <div class="cart-title-label">Mi pedido</div>

            <div class="cart-title-option" (click)="irAHome()">
              Continuar comprando
            </div>
          </div>
          <div class="cart-info bgGrey">
            <div class="cart-total">
              <div class="cart-total-label">Total</div>
              <div class="cart-total-mount">
                {{ TotalCart | brazilPipe }}
              </div>
            </div>
            <div class="cart-bottom">
              <div class="cart-bottom-info">
                <div class="btn-comprar" (click)="irACheckout()">
                  <span>Comprar</span>

                  <div
                    class="cartSvg"
                    [inlineSVG]="'/assets/imgs/svg/cart.svg'"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
