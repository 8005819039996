import { ProductVariation } from './../../classes/productVariation';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/classes/product';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart : BehaviorSubject<any> = new BehaviorSubject([]);
  private cartCount : BehaviorSubject<Number> = new BehaviorSubject(0);
  private cartTotal : BehaviorSubject<Number> = new BehaviorSubject(0);

  constructor(
  ) {
    this.loadCart();
  }

  addItemTocart(product : any, variation: any, quantity : number = 1)
  {
      let cart = JSON.parse(localStorage.getItem("cart"));
      let cartCount = 0;
      let cartTotal = 0;
      if(cart==null)
      {
        cart=[];
        variation.quantity = quantity;
          let ItemCart = {
            product: product,
            variation: variation
          };
        cart.push(ItemCart);
        cartCount = quantity;
        cartTotal = 0;
      }else{
        let find = false;
        cart.map( (item) =>{
            if((item.product.sku == product.sku) && (item.variation.cod == variation.cod)){
              item.variation.quantity+=quantity;
              find = true;
            }
            return item;
        });

        if(!find)
        {
          variation.quantity = quantity;
          let ItemCart = {
            product: product,
            variation: variation
          };
        cart.push(ItemCart);
        }

        cart.forEach(c => {
          cartCount += c.variation.quantity;
          cartTotal += c.variation.quantity * c.variation.price;
        });
      }

      localStorage.setItem("cart",JSON.stringify(cart));
      this.cartCount.next(cartCount);
      this.cartTotal.next(cartTotal);
      this.setCart(cart);
  }

  updateCart(cart : any)
  {
    let cartCount = 0;
    let cartTotal = 0;

    if(cart==null)
    {
      cart=[];
      cartCount = 0;
      cartTotal = 0;
    }else{
      cart.forEach(c => {
        cartCount += c.variation.quantity;
        cartTotal += c.variation.quantity * c.variation.price;
      });
    }

    this.setCart(cart);
    this.cartCount.next(cartCount);
    this.cartTotal.next(cartTotal);
  }

  setCart(cart: any) : void {
    localStorage.setItem("cart",JSON.stringify(cart));
    return this.cart.next(cart);
  }

  getCart() : any {
    return this.cart
  }

  getCartCount() : any {
    return this.cartCount
  }


  getCartTotal() : any {
    return this.cartTotal
  }

  private loadCart()
  {
    let cart = JSON.parse(localStorage.getItem("cart"));
    let cartCount = 0.;
    let cartTotal = 0.;

    if(cart==null)
    {
      cart=[];
      cartCount = 0;
      cartTotal = 0;
    }else{
      cart.forEach(c => {
        cartCount += c.variation.quantity;
        cartTotal += (c.variation.quantity * c.variation.price);
      });
    }

    this.setCart(cart);
    this.cartCount.next(cartCount);
    this.cartTotal.next(cartTotal);
  }


  clear() {
    this.setCart([]);
    this.cartCount.next(0);
    this.cartTotal.next(0);
  }
}
