<div class="fondo">
    <div class="head container-fluid">
        <div class="logo mb-0 d-none">
            <a>
                <img class="fluid-img" src="/assets/imgs/logo_g.png">
            </a>
        </div>
        <div *ngIf="datosLanding!=[]" class="d-xl-block d-none"><img src="{{datosLanding.bannerDesktop}}" alt="Banner Promoción" class="img-fluid"></div>
        <div *ngIf="datosLanding!=[]" class="d-xl-none d-sm-block d-none"><img src="{{datosLanding.bannerTablet}}" alt="Banner Promoción" class="img-fluid"></div>
        <div *ngIf="datosLanding!=[]" class="d-sm-none d-block"><img src="{{datosLanding.bannerMovil}}" alt="Banner Promoción" class="img-fluid"></div>
        <!--
        <div class="texto-promo mx-auto mt-3 px-5 py-3">
            <p class="px-3"><small>El mejor regalo está a un</small><br>chat de distancia</p>
            <p class="px-3"><small>Compra por</small> <span> WhatsApp</span></p>
        </div>
        -->

    </div>
    <div class="container-lg">
        <div class="texto pt-4">
            <h2>Compra Pandora desde donde estés con tu teléfono.</h2>
            <h3>Sigue los pasos para saber cómo. </h3>
        </div>
        <div class="row my-5">
            <div class="col-md-3 col-sm-6 col-12 text-center">
                <img src="/assets/imgs/common_ico_01.jpg" alt="">
                <p class="texto-caja">Selecciona tu tienda más cercana en el mapa.</p>
            </div>
            <div class="col-md-3 col-sm-6 col-12 text-center">
                <img src="/assets/imgs/common_ico_02.jpg" alt="">
                <p class="texto-caja">Contacta directamente a una asesora Pandora a través de Whatsapp.</p>
            </div>
            <div class="col-md-3 col-sm-6 col-12 text-center">
                <img src="/assets/imgs/common_ico_03.jpg" alt="">
                <p class="texto-caja">Elige los productos de tu interés y selecciona el método de pago.</p>
            </div>
            <div class="col-md-3 col-sm-6 col-12 text-center">
                <img src="/assets/imgs/common_ico_04.jpg" alt="">
                <p class="texto-caja">¡Listo! Puedes retirar los productos en tienda o coordinar el envío a domicilio.</p>
            </div>

        </div>



    </div>
    <div class="container-lg">
        <div class="texto pt-4">
            <h3>¿Qué esperas?</h3>
        </div>

    </div>
    <!--
      <owl-carousel-o class="col-12 noPadding" [options]="customOptions" id="carouselHome">
                  <ng-template carouselSlide  *ngFor="let baner of banners; let i=index" [width]="baner.width/(baner.height/250)">
                      <div class="itemCarou" >
                          <img class="imgCarousel" #imageDiv  [src]="baner.url">
                      </div>
              </ng-template>
      </owl-carousel-o>
  -->
    <div class="container py-4">
        <div class="row">
            <div class="col-12">
                <div class="form-group divSearch">
                    <input type="text" class="form-control" (keydown.enter)="searchMap($event)" placeholder="Elige tu tienda más cercana y ten tu Pandora." autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                    <i class="fas fa-search" (click)="searchMap2(search.value)"></i>
                </div>
                <div class="mapa-container">

                    <ol-map #map [lat]="lat" [lon]="lng" [zoom]="zoom" width="100%" height="400px" [markers]="markers" (centroMap)="procesarData($event)"></ol-map>


                </div>
                <div class="list-lojas">
                    <h5 *ngIf="tiendas_cercanas.length>0">Tiendas cercanas</h5>

                    <div class="tienda-list" *ngIf="tiendas_cercanas.length>0">
                        <div class="distance" *ngFor="let tienda of tiendas_cercanas;  index as j" (click)="selectMarker(tienda)">
                            <span>{{tienda.distance}}km</span> <i class="fas fa-map-marker"></i><b>{{tienda.title}}</b>
                        </div>

                    </div>
                    <p *ngIf="tiendas_cercanas.length==0">
                        No hay tiendas (Distancia mayor a 10km)
                    </p>

                </div>

            </div>
        </div>
    </div>
    <div class="container py-4" *ngIf="catalogo_true">
        <div class="texto pt-4">
            <h3>Conoce nuestro universo.</h3>
        </div>
        <iframe allowfullscreen style="border: 0; width: 100%; height: 60vh;" [src]="catalogo"></iframe>

    </div>
    <swal #problem text="This cannot be undone" icon="question" [showCancelButton]="true" [focusCancel]="true">
    </swal>
    <div class="container py-4" >
        <p class="text-legal" *ngIf="datosLanding.editorText" [innerHtml]="processHTML(datosLanding.editorText)">

        </p>
    </div>
</div>
