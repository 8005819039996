export interface IOrder {
  id_store: string,
  customer: {
     name: string,
     lastname: string,
     email: string,
     phone: string,
     cpf: string,
     id_delivery?: string,
     deliveryAddress ?: {
      type ?: string,
      street ?: string,
      number ?: string,
      complement ?: string,
      zip_code ?: string,
      city ?: string,
      state ?: string,
      country ?: string,
      district ?: string,
      cep ?: string,
      endereco ?: string,
      neighborhood ?: string
    }
  },
  payment:{
     id_payment: string,
     type: string,
     card:{
        cardNumber: string,
        holder: string,
        expirationDate: string,
        securityCode: string,
        brand: string,
     }
     installments: number,
  },
  products: any,

}

export class Order
{
  id_store: string;
  customer:{
     name: string;
     lastname: string;
     email: string
     phone: string;
     cpf: string;
     id_delivery?: string;
     deliveryAddress ?: {
      type ?: string;
      street ?: string;
      number ?: string;
      complement ?: string;
      zip_code ?: string;
      city ?: string;
      state ?: string;
      country ?: string;
      district ?: string;
      cep ?: string;
      endereco ?: string;
      neighborhood ?: string;
    }
  };
  payment:{
     id_payment: string;
     type: string;
     card:{
        cardNumber: string;
        holder: string;
        expirationDate: string;
        securityCode: string;
        brand: string;
     }
     installments: number,
  };
  products: any;

  constructor(order : IOrder) {
    this.id_store = order.id_store;
    this.customer = order.customer;
    this.payment = order.payment;
    this.products = order.products;
  }
}
