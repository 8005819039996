<div class="space-header">
    <div class="header">
        <div class="container">
            <div class="row">
                <div class="head">
                    <div class="logo">
                        <a  (click)="irAHome()">
                        <div [inlineSVG]="'/assets/imgs/svg/logo.svg'"></div>
                         </a>
                    </div>
                    <div class="opciones">
                        <div class="link-opcion" (click)="irGeo()">
                            <div class="placeSvg" [inlineSVG]="'/assets/imgs/svg/place.svg'"></div>
                        </div>
                        <a class="link-opcion" (click)="irACart()">
                            <div class="cartSvg" [inlineSVG]="'/assets/imgs/svg/cart.svg'"></div>
                            <div class="amount-items" [@inOutAnimation]=currentState>
                                <span>{{cartCount}}</span>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>

        <div class="menu">
                <nav class="navbar navbar-expand-xl navbar-toggleable-md navbar-light">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse " id="navbarNavDropdown">
                    <ul class="principalCategory navbar-nav justify-content-between">
                        <li *ngIf="menu.length>0 && promotionInfo && ( promotionInfo.type=='002' ||  promotionInfo.type=='004'  ||  promotionInfo.type=='005')" class="list nav-item dropdown">
                            <span class="nav-link" (click)="openPromotion()">
                                <span class="especial-nav">{{promotionInfo.name}}</span>
                             </span>
                        </li >
                        <li *ngFor="let option of menu;let i = index" class="list nav-item dropdown">
                        <span class="nav-link" (click)="openSubMenu(option,i,option.slug)">
                                <span>{{option.name}}</span>
                        </span>
                        </li>
                
                    </ul>
                </div>
            </nav>
        </div>

    </div>

</div>
